import React from 'react'

function PrivacyPolicy() {
  return (
    <div className='row'>
      <div className='col-xl-8'>
      <h1>FIRE CHIEFS’ ASSOCIATION OF BC</h1>
      <p>The FireSmart™ BC Wildfire Mitigation Program (previously the Home Partners Program) has undergone an ownership transition from FireSmart Canada to FireSmart BC. As such, FireSmart BC, in partnership with the Fire Chiefs’ Association of BC (“FCABC”), have developed a new platform, the FireSmart™ BC platform (the “platform”). The platform will be utilized by FireSmart professionals to complete FireSmart assessments, as well as receive FireSmart training and e-learning. This Personal Information Protection Policy outlines FCABC’s commitment to protecting all personal information collected on the platform.</p>
      
      <h2>Personal Information Protection Policy</h2>
      <p>At the FCABC, we are committed to providing our clients and users with exceptional service. As providing this service involves the collection, use and disclosure of some personal information about our clients and users, protecting their personal information is our highest priority.</p>
      <p>We respect our clients’ and users’ privacy and work to safeguard their personal information, in accordance with the Personal Information Protection Act (PIPA). PIPA, which came into effect on January 1, 2004, sets out the ground rules for how B.C. businesses and not-for-profit organizations may collect, use and disclose personal information.</p>
      <p>We will inform our clients and users of why and how we collect, use and disclose their personal information, obtain their consent where required, and only handle their personal information in a manner that a reasonable person would consider appropriate in the circumstances.</p>
      <p>This Personal Information Protection Policy, in compliance with PIPA, outlines the principles and practices we will follow in protecting clients’ and users’ personal information. Our privacy commitment includes ensuring the accuracy, confidentiality, and security of our clients’ and users’ personal information and allowing our clients and users to request access to, and correction of, their personal information.</p>
      
      <h2>Scope of this Policy</h2>
      <p>This Personal Information Protection Policy applies to FCABC and its subsidiaries and any service providers collecting, using or disclosing personal information on behalf of FCABC.</p>
      
      <h2>Definitions</h2>
      <p><strong>Personal Information</strong> – means information about an identifiable individual. Personal information does not include contact information (described below).</p>
      <p><strong>Contact information</strong> – means information that would enable an individual to be contacted at a place of business and includes name, position name or title, business telephone number, business address, business email or business fax number. Contact information is not covered by this policy or PIPA.</p>
      <p><strong>Privacy Officer</strong> – means the individual designated responsibility for ensuring that FCABC complies with this policy and PIPA. Currently this person is Wildfire Mitigation Program Administrative Coordinator.</p>
      <p><strong>Clients</strong> - means homeowners, property owners, land owners, and critical infrastructure owners.</p>
      <p><strong>Users</strong> - means FireSmart Professionals (FireSmart BC team members, FireSmart Coordinators, Wildfire Mitigation Specialists, Local FireSmart Representatives).</p>
      
      <h2>Policy 1 – Collecting Personal Information</h2>
      <p>1.1 Unless the purposes for collecting personal information are obvious and the client or user voluntarily provides their personal information for those purposes, we will communicate the purposes for which personal information is being collected, either orally or in writing, before or at the time of collection.</p>
      <p>1.2 We will only collect client and user information that is necessary to fulfill the following purposes:</p>
      <ul>
        <li>To administer participation in the FireSmart platform, which includes delivering and receiving FireSmart assessments, and receiving FireSmart training and e-learning;</li>
        <li>To verify identity;</li>
        <li>To identify client and user preferences;</li>
        <li>To understand the needs of, and ensure a high standard of service to, our clients and users;</li>
        <li>To meet regulatory requirements;</li>
        <li>To personalize client and user experience, improve our web platform, and improve customer service.</li>
      </ul>
      <h3>1.3 Cookies</h3>
      <p>Anonymous technical information may be collected by us as a result of your use of the FireSmart Platform. For example, this information may include your IP address, browser type, operating system, domain name, access times and referring website addresses. We use this anonymous technical information for purposes such as improving the operation and content of the platform and compiling aggregate and statistical information.</p>
      <p>We may, from time to time, collect non-personal information about you and your use of the FireSmart Platform through the use of cookies. We do not match or correlate this information with any personal information that you submit on the FireSmart Platform. “Cookies” are small text files placed on your computer that can collect and store a variety of information. Permanent cookies are stored indefinitely on a user’s hard drive unless manually deleted, while temporary cookies are automatically deleted from the user’s browser upon logging out of a website. Web browsers typically allow users to disable permanent and/or temporary cookies.</p>
      
      <h2>Policy 2 – Consent</h2>
      <p>2.1 We will obtain client and user consent to collect, use or disclose personal information (except where, as noted below, we are authorized to do so without consent).</p>
      <p>2.2 Consent can be provided orally, in writing, or electronically or it can be implied where the purpose for collecting, using or disclosing the personal information would be considered obvious and the client or user voluntarily provides personal information for that purpose.</p>
      <p>2.3 Subject to certain exceptions (e.g., the personal information is necessary to provide the service or product, or the withdrawal of consent would frustrate the performance of a legal obligation), clients or users can withhold or withdraw their consent for FCABC to use their personal information in certain ways. A client or user’s decision to withhold or withdraw their consent to certain uses of personal information may restrict our ability to provide a particular service or product. If so, we will explain the likely consequences of the client or user withdrawing their consent to assist the client or user in making the decision.</p>
      <p>2.4 We may collect, use or disclose personal information without the client or user’s knowledge or consent in the following limited circumstances:</p>
      <ul>
        <li>When the collection, use or disclosure of personal information is permitted or required by law;</li>
        <li>In an emergency that threatens an individual's life, health, or personal security;</li>
        <li>When the personal information is available from a public source (e.g., a telephone directory);</li>
        <li>When we require legal advice from a lawyer;</li>
        <li>To investigate an anticipated breach of an agreement or a contravention of law.</li>
      </ul>
      
      <h2>Policy 3 – Using and Disclosing Personal Information</h2>
      <p>3.1 We will only use client or user personal information as contemplated in section 1.2 above and will only disclose client or user personal information where necessary to fulfill the purposes identified at the time of collection such as:</p>
      <ul>
        <li>To administer participation in the FireSmart platform, which includes delivering and receiving FireSmart assessments, and receiving FireSmart training and e-learning;</li>
        <li>To verify identity;</li>
        <li>To identify client and user preferences;</li>
        <li>To understand the needs of, and ensure a high standard of service to, our clients and users;</li>
        <li>To meet regulatory requirements;</li>
        <li>To personalize client and user experience, improve our web platform, and improve customer service;</li>
        <li>To quantify aggregate assessment data for the purposes of reporting to the BCFSC as well as improving how we deliver and promote our programs. However, we will not disclose any client contact or user location specific data.</li>
      </ul>
      <p>3.2 We will not use or disclose client or user personal information for any additional purpose unless we obtain consent to do so.</p>
      <p>3.3 We will not sell client or user lists or personal information to other parties unless we have consent to do so.</p>
      
      <h2>Policy 4 – Retaining Personal Information</h2>
      <p>4.1 If we use client or user personal information to make a decision that directly affects the client or user, we will retain that personal information for at least one year so that the client or user has a reasonable opportunity to request access to it.</p>
      <p>4.2 Subject to policy 4.1, we will retain client or user personal information only as long as necessary to fulfill the identified purposes, at which time we will securely destroy or anonymize the personal information.</p>
      
      <h2>Policy 5 – Ensuring Accuracy of Personal Information</h2>
      <p>5.1 We will make reasonable efforts to ensure that client and user personal information is accurate and complete where it may be used to make a decision about the client or user or disclosed to another organization.</p>
      <p>5.2 Clients or users may request correction to their personal information in order to ensure its accuracy and completeness. A request to correct personal information must be made in writing and provide sufficient detail to identify the personal information and the correction being sought. A request to correct personal information should be forwarded to the Privacy Officer.</p>
      <p>5.3 If the personal information is demonstrated to be inaccurate or incomplete, we will correct the information as required and send the corrected information to any organization to which we disclosed the personal information in the previous year. If the correction is not made, we will note the clients or users’ correction request in the file.</p>
      
      <h2>Policy 6 – Securing Personal Information</h2>
      <p>6.1 We are committed to ensuring the security of client or user personal information in order to protect it from unauthorized access, collection, use, disclosure, copying, modification or disposal or similar risks.</p>
      <p>6.2 The following security measures will be followed to ensure that client, or user personal information is appropriately protected:</p>
      <ul>
        <li><strong>Data Protection</strong>
          <ul>
            <li>Encryption: TLS/SSL for data in transit, AES-256 for data at rest</li>
            <li>Access Control: IAM roles and policies, multi-factor authentication (MFA)</li>
            <li>Monitoring: AWS CloudTrail and CloudWatch for logging and monitoring</li>
          </ul>
        </li>
        <li><strong>Application Security</strong>
          <ul>
            <li>Web Application Firewall (WAF): Protect against common web exploits</li>
            <li>Intrusion Detection: AWS GuardDuty for continuous security monitoring</li>
            <li>Regular Audits: Security audits and vulnerability assessments as well as timely response to any suspicious activity.</li>
          </ul>
        </li>
        <li>Restricting employee and contractor access to personal information as appropriate (i.e., only those that need to know will have access).</li>
      </ul>
      <p>6.3 We will use appropriate security measures when destroying client’s or user’s personal information, such as:</p>
      <ul>
        <li>Physical Copies: Will not be kept by the FCABC and therefore not applicable</li>
        <li>Digital Data: Will be stored within the application, un-finished/lapsed assessments will be deleted after 7 years</li>
      </ul>
      <p>6.4 We will continually review and update our security policies and controls as technology changes to ensure ongoing personal information security.</p>
      
      <h2>Policy 7 – Providing Clients or Users Access to Personal Information</h2>
      <p>7.1 Clients and users have a right to access their personal information, subject to the limited exceptions as outlined in <a className='underline' href="https://www.bclaws.gov.bc.ca/civix/document/id/complete/statreg/03063_01#section23" target="_blank" rel="noopener noreferrer">section 23 of PIPA</a>.</p>
      <p>7.2 A request to access personal information must be made in writing and provide sufficient detail to identify the personal information being sought. A request to access personal information should be forwarded to the Privacy Officer via the platform.</p>
      <p>7.3 Upon request, we will also tell clients or users how we use their personal information and to whom it has been disclosed if applicable.</p>
      
      <h2>Policy 8 – Questions and Complaints: The Role of the program administrative coordinator</h2>
      <p>8.1 The Program Administrative Coordinator is responsible for ensuring FCABC’s compliance with this policy and PIPA.</p>
      <p>8.2 Clients or users should direct any complaints, concerns or questions regarding FCABC’s compliance in writing to the Program Administrative Coordinator. If the Program Administrative Coordinator is unable to resolve the concern, the client or user may also write to the <a className='underline' href="https://www.oipc.bc.ca/" target="_blank" rel="noopener noreferrer">Office of the Information and Privacy Commissioner for British Columbia</a>.</p>
      <p>Contact information for FireSmart’s Program Administrative Coordinator is as follows: <a className='underline' href="mailto:info@firesmartbc.ca" target="_blank" rel="noopener noreferrer">info@firesmartbc.ca</a>.</p>
      </div>
    </div>
  )
}

export default PrivacyPolicy