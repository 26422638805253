import { useState, useEffect } from "react";
import api from "../shared/utils/api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import FormGroupInput from "../shared/components/FormGroupInput";
import FormGroupProvinceDropdownInput from "../shared/components/FormGroupProvinceDropdownInput";
import Button from "react-bootstrap/Button";
import {Form, Row, InputGroup} from "react-bootstrap";
import { ERROR_MESSAGE_DURATION } from "../shared/Constants";
import PostalCodeInput from "../shared/components/PostalCodeInput";
import PhoneNumberInput from "../shared/components/PhoneNumberInput";
import Select from "react-select";
import RoleDropdown from "./RoleDropdown";
import { generateUsername } from "../shared/utils/usernameHelper";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons
import EmailInput from "../shared/components/EmailInput";


function AddUser() {
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [validated, setValidated] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState(""); 
  const [showPassword, setShowPassword] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("BC");
  const [postalCode, setPostalCode] = useState("");
  const [userStatus, setUserStatus] = useState("Active");
  const [territories, setTerritories] = useState([]);
  const [selectedTerritories, setSelectedTerritories] = useState([
    { value: 1, label: "Unassigned" },
  ]);
  const [userRoleId, setUserRoleId] = useState(4);

  const navigate = useNavigate();

  useEffect(() => {
    api.get("/territories").then(
      (response) => {
        const territories = response
          .map((territory) => ({
            value: territory.id,
            label: territory.name,
          }))
          .filter((option) => option.value !== 1);
        setTerritories(territories);
      },
      (error) => {
        toast.error("Failed to load territories. Error: " + error, {
          autoClose: ERROR_MESSAGE_DURATION,
        });
      },
    );
  }, []);

  const handleTerritoryChange = (selected) => {
    if (selected) {
      const filteredSelected = selected.filter((option) => option.value !== 1); // Assuming Unassigned has value = 1
      setSelectedTerritories(
        filteredSelected.length > 0
          ? filteredSelected
          : [{ value: 1, label: "Unassigned" }],
      );
    } else {
      setSelectedTerritories([{ value: 1, label: "Unassigned" }]);
    }
  };

  const addUser = () => {
    //TODO: Change this - it should only send one request to the server and the server handles
    // coginto and creating the account in the app. If the account already exists in cognito
    // but not in the app then it will just create the user in the app.

   

    var generatedUsername = generateUsername(email);
    const territoryIds = selectedTerritories.map(
      (territory) => territory.value,
    );

    api
      .post("/cognito-users", {
        password: password,
        email: email.trim().toLowerCase(),
        username: generatedUsername,
        firstName: firstName,
        lastName: lastName
      })
      .then(
        (response) => {
          // No toast as our end users don't need to know about cognito users
        },
        (error) => {
          if (error.message.startsWith("UsernameExistsException")) {
            // If the user already exists in cognito, we will try to create the firesmart
            // user. This should be safe as the username is marked as unique in the
            // database.
            // No toast as our end users don't need to know about cognito users
          } else {
            // If we fail to create the cognito user, we don't want to create the firesmart user
            toast.error("Failed to record username and password to database", {
              autoClose: ERROR_MESSAGE_DURATION,
            });
            return;
          }
        },
      );

    api
      .post("/firesmart-users", {
        firstName: firstName,
        lastName: lastName,
        email: email.trim().toLowerCase(),
        username: generatedUsername,
        // TODO: input sanitization
        phoneNumber: phoneNumber?.startsWith("+1")
          ? phoneNumber?.replace(/[\(\)-]/g, "")
          : "+1" + phoneNumber?.replace(/[\(\)-]/g, ""),
        address: address,
        city: city,
        province: province,
        postalCode: postalCode,
        userStatus: userStatus,
        userRoleID: +userRoleId,
        territoryIds: territoryIds,
      })
      .then(
        (response) => {
          toast.success("User Added", { autoClose: 2000 });
          navigate(-1);
        },
        (error) => {
          toast.error("Failed to add user", {
            autoClose: ERROR_MESSAGE_DURATION,
          });
        },
      );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false || password !== confirmPassword) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
      if (password !== confirmPassword) {
        toast.error("Passwords do not match", {
          autoClose: ERROR_MESSAGE_DURATION,
        });
      }
      return; // Return if validation failed
    }

    // email is duplicate
    if (isDuplicate === true) return;

    setValidated(true);

    addUser();
  };

  const handleCancel = () => {
    navigate("/users");
  };

  return (
    <div>
      <div class="form-container">
        <h4>Add User</h4>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row>
            <FormGroupInput
              label="First Name"
              name="firstName"
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required={true}
              pattern="[a-zA-Z ]+"
              invalidFeedback="Names may contain alphabetical characters only."
            />
            <FormGroupInput
              label="Last Name"
              name="lastName"
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required={true}
              pattern="[a-zA-Z ]+"
              invalidFeedback="Names may contain alphabetical characters only."
            />
          </Row>
          <Row className="flex flex-col lg:flex-row">
           <InputGroup className="form-group lg:w-1/2 !flex-none mb-3">
              <FormGroupInput              
                controlId="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required={true}
                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_])[A-Za-z\d@$!%*?&_]{8,}$"
                invalidFeedback="Password must be at least 8 characters long, contain an uppercase and lowercase letter, a number, and a special character."
              />
               <InputGroup.Text 
                onClick={() => setShowPassword(!showPassword)}
                style={{
                  position: 'absolute',
                  right: '10px',
                  bottom: '7px',                  
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer'
                }}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </InputGroup.Text>
            </InputGroup>
            <InputGroup className="form-group lg:w-1/2 !flex-none mb-3">
              <FormGroupInput
           
                controlId="confirmPassword"
                label="Confirm Password"
                type={showPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required={true}
                invalidFeedback="Passwords must match."
              />
              <InputGroup.Text 
                onClick={() => setShowPassword(!showPassword)}
                style={{
                  position: 'absolute',
                  right: '10px',
                  bottom: '7px',                  
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer'
                }}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </InputGroup.Text>
            </InputGroup>
          </Row>
          <Row>
            <EmailInput
              value={email}
              onChange={(newEmail) => setEmail(newEmail)}
              isDuplicate={isDuplicate}
              setIsDuplicate={setIsDuplicate}
            />
          </Row>
          <Row>
            <Form.Group controlId="userRole" class="form-group">
              <RoleDropdown
                selectedRole={userRoleId}
                setSelectedRole={(e) => setUserRoleId(e)}
              />
            </Form.Group>
          </Row>
          <Row>
            <PhoneNumberInput
              controlId="phoneNumber"
              label="Phone Number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e)}
              required={false}
              invalidFeedback="Must provide a phone number in the format 123-456-7890."
              pattern="^\d{3}-\d{3}-\d{4}$"
            />
          </Row>
          <Row>
            <FormGroupInput
              label="Address"
              name="address"
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required={false}
              invalidFeedback="Must provide an address with allowed special characters .:-_', and allowed spaces."
              pattern="^[a-zA-Z0-9 .:,-_']+$"
            />
          </Row>
          <Row>
            <FormGroupInput
              label="City"
              name="city"
              type="text"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              required={false}
              invalidFeedback="Must provide a city with allowed special characters .:-_', and allowed spaces."
              pattern="^[a-zA-Z0-9 .:,-_']+$"
            />
          </Row>
          <Row>
            <FormGroupProvinceDropdownInput
              value={province}
              onChange={(e) => setProvince(e.target.value)}
              placeHolderRequired={true}
              required={false}
            />
          </Row>
          <Row>
            <PostalCodeInput
              controlId="postalCode"
              label="Postal Code"
              type="text"
              invalidFeedback="Must provide a postal code matching the A1A 1A1 pattern."
              value={postalCode}
              onChange={(e) => setPostalCode(e)}
              pattern="^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$"
            />
          </Row>
          <Row>
            <div className="form-group">
              <label>Territories</label>
              <Select
                isMulti
                options={territories}
                value={selectedTerritories}
                onChange={handleTerritoryChange}
                placeholder="Select Territories"
              />
            </div>
          </Row>
          <Row>
            <Form.Group class="form-group">
              <Button type="submit" className="btn-standard margin-right-10">
                Save
              </Button>
              <Button
                type="submit"
                className="btn-standard danger"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Form.Group>
          </Row>
        </Form>
      </div>
    </div>
  );
}

export default AddUser;
