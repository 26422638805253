import React, { useState } from "react";
import { motion } from "framer-motion";
import { AiOutlineSearch } from "react-icons/ai";
import { CiMail } from "react-icons/ci";
import { FaChevronDown } from 'react-icons/fa';
import { VscFeedback } from "react-icons/vsc";


function AssessorSupportPage({ title, paragraph, questions }) {
    const [searchQuery, setSearchQuery] = useState("");

    const filteredFaqs = questions.filter(faq =>
        faq.question.toLowerCase().includes(searchQuery.toLowerCase()) ||
        faq.answer.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div>
            <h1>{title}</h1>
            <div className="grid grid-cols-12 gap-6">
                <div className="col-span-full lg:col-span-8 lg:pr-8">
                    <p className="text-2xl">{paragraph}</p>
                    <div className="relative inline-block mb-4">
                        <input
                            type="text"
                            placeholder="Search FAQs..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            className="px-4 pt-3 pb-2.5 w-[350px] border border-gray-300 rounded-full"
                        />
                        <AiOutlineSearch className="absolute h-6 w-6 right-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
                    </div>
                    {filteredFaqs.length > 0 ? (
                        <FAQ faqs={filteredFaqs} />
                    ) : (
                        <p>No results found! Please try a different search.</p>
                    )}
                </div>
                <div className="col-span-full lg:col-span-4">
                    <div className='p-8 mb-4 rounded-lg border border-border text-black box-content flex flex-row flex-nowrap'>                       
                        <div className="flex-shrink">
                            <h3>Have a bug or want to give feedback?</h3>
                            <p className='mt-2.5 text-lg xl:pr-16'> Please share your feedback with our team to help fix any issues and deliver a better experience.</p>
                            <a href="https://form.asana.com/?k=umIcgThBycq_ztFySVh8Ag&d=1209185288846706" target="_blank" className="btn btn-standard btn-outline  ">
                                <span className="inline-block">Feedback form</span> <VscFeedback size={20} className="inline-block ml-2" />
                            </a>
                        </div>
                    </div>
                    <div className='p-8 rounded-lg bg-slate text-white box-content flex flex-row flex-nowrap'>
                        <img className="w-[60px] h-[60px] mr-2 flex-shrink-0" src="/attila.png" alt="Assessor Support" />
                        <div className="flex-shrink">
                            <h3>Still have questions?</h3>
                            <p className='mt-2.5 text-lg xl:pr-16'>Can’t find the answer you’re looking for? Please send a message to our friendly team.</p>
                            <a href="mailto:wmp.support@firesmartbc.ca" className="btn btn-standard btn-outline border-white text-white hover:!bg-primary hover:!text-slate hover:!border-primary">
                                <span className="inline-block">Get in touch</span> <CiMail size={20} className="inline-block ml-2" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AssessorSupportPage;

function FAQ({ faqs }) {
    return (
        <div>
            {faqs.map((faq, index) => (
                <FAQItem key={index} question={faq.question} answer={faq.answer} />
            ))}
        </div>
    );
}

function FAQItem({ question, answer }) {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    return (
        <motion.div 
            className="faq-item border-b border-border pt-8 pb-12 cursor-pointer"
            initial={false}
            animate={{ height: isOpen ? "auto" : "0px" }}
            transition={{ duration: 0.2, ease: "easeInOut" }}
        >
            <div 
                className="flex flex-row flex-nowrap justify-between items-center cursor-pointer"
                onClick={toggleOpen}
            >
                <h6 className="font-bold pr-4">{question}</h6>
                <motion.div 
                    className="mb-auto"
                    animate={{ rotate: isOpen ? 180 : 0 }}
                    transition={{ duration: 0.2, ease: "easeIn" }}
                >
                    <FaChevronDown />
                </motion.div>
            </div>
            {isOpen && (
                <motion.p 
                    className="mt-2.5 mb-0 underline-links"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.2, ease: "easeInOut" }}
                    dangerouslySetInnerHTML={{ __html: answer }}
                />
            )}
        </motion.div>
    );
}