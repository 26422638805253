import React from "react";
import { useState, useEffect } from "react";
import api from "../shared/utils/api";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import UserCertification from "./UserCertification";
import ChangePassword from "./ChangePassword";
import FormGroupInput from "../shared/components/FormGroupInput";
import FormGroupProvinceDropdownInput from "../shared/components/FormGroupProvinceDropdownInput";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { ERROR_MESSAGE_DURATION } from "../shared/Constants";
import PostalCodeInput from "../shared/components/PostalCodeInput";
import PhoneNumberInput from "../shared/components/PhoneNumberInput";
import Select from "react-select";
import RoleDropdown from "./RoleDropdown";

function User() {
  //TODO: This could be cleaned up using a reducer to manage complex state instead of having all
  //      these useState variables
  const [validated, setValidated] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [username, setUsername] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [userStatus, setUserStatus] = useState("");
  const [territories, setTerritories] = useState([]);
  const [selectedTerritories, setSelectedTerritories] = useState([
    { value: 1, label: "Unassigned" },
  ]);
  const [userRoleId, setUserRoleId] = useState();

  const [certificationEntitlements, setCertificationEntitlements] = useState(
    [],
  );
  const [certifications, setCertifications] = useState([]);

  const [showChangePassword, setShowChangePassword] = useState(false);

  const userStatuses = [
    { id: "Active", name: "Active" },
    { id: "Disabled", name: "Disabled" },
  ];

  const navigate = useNavigate();

  const params = useParams();

  const { id } = params;

  const fetchCertifications = () => {
    return api.get("/certifications").then(
      (response) => response,
      (error) => {
        toast.error("Error fetching certifications", {
          autoClose: ERROR_MESSAGE_DURATION,
        });
        throw error;
      },
    );
  };

  const fetchCertificationEntitlements = () => {
    return api.get("/certification-entitlements?userID=" + id).then(
      (response) => response,
      (error) => {
        toast.error("Error fetching certification entitlements", {
          autoClose: ERROR_MESSAGE_DURATION,
        });
        throw error;
      },
    );
  };

  const fetchTerritories = () => {
    return api.get("/territories").then(
      (response) => {
        const territoriesData = response
          .map((territory) => ({
            value: territory.id,
            label: territory.name,
          }))
          .filter((option) => option.value !== 1);
        return territoriesData;
      },
      (error) => {
        toast.error("Failed to load territories. Error: " + error, {
          autoClose: ERROR_MESSAGE_DURATION,
        });
        throw error;
      },
    );
  };

  const fetchUser = () => {
    return api.get("/firesmart-users/" + id).then(
      (response) => {
        if (response === null || response === undefined) {
          throw new Error("User not found");
        }
        return response;
      },
      (error) => {
        toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
        throw error;
      },
    );
  };

  useEffect(() => {
    Promise.all([
      fetchTerritories(),
      fetchUser(),
      fetchCertifications(),
      fetchCertificationEntitlements(),
    ])
      .then(
        ([
          territoriesData,
          userData,
          certificationsData,
          certificationEntitlementsData,
        ]) => {
          setTerritories(territoriesData);
          setFirstName(userData.firstname);
          setUsername(userData.username);
          setLastName(userData.lastname);
          setAddress(userData.address);
          setCity(userData.city);
          setProvince(userData.province);
          setPostalCode(userData.postalCode);
          setEmail(userData.email);
          setPhoneNumber(userData.phoneNumber?.replace("+1", ""));
          setUserStatus(userData.userStatus);
          setUserRoleId(userData.userRoleID);

          if (certificationsData) setCertifications(certificationsData);
          if (certificationEntitlementsData)
            setCertificationEntitlements(certificationEntitlementsData);

          if (userData.territoryIds?.length > 0) {
            const selectedOptions = territoriesData.filter((option) =>
              userData.territoryIds.includes(option.value),
            );
            setSelectedTerritories(selectedOptions);
          }
        },
      )
      .catch((error) => {
        console.error("Error fetching data:", error);
        toast.error("Error fetching data: " + error, {
          autoClose: ERROR_MESSAGE_DURATION,
        });
      });
  }, [id]);

  const updateUser = () => {
    const territoryIds = selectedTerritories.map(
      (territory) => territory.value,
    );

    api
      .post("/firesmart-users", {
        userID: parseInt(id),
        firstname: firstName,
        username: username,
        lastname: lastName,
        address: address,
        city: city,
        province: province,
        postalCode: postalCode,
        email: email,
        phoneNumber: phoneNumber?.startsWith("+1")
          ? phoneNumber?.replace(/[\(\)-]/g, "")
          : "+1" + phoneNumber?.replace(/[\(\)-]/g, ""),
        userStatus: userStatus,
        userRoleID: +userRoleId,
        territoryIds: territoryIds,
      })
      .then(
        (response) => {
          toast.success("User updated", { autoClose: 2000 });
        },
        (error) => {
          toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
        },
      );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
      return; // Return if validation failed
    }

    setValidated(true);

    updateUser();
  };

  const handleCancel = (e) => {
    e.preventDefault();
    navigate("/Users");
  };

  const handleGrantCertification = (certID) => {
    api
      .post("/certification-entitlements/grant", {
        userID: parseInt(id),
        certificationID: certID,
      })
      .then(
        () => {
          fetchCertificationEntitlements().then((ce) =>
            setCertificationEntitlements(ce),
          );
          toast.success("Certification granted", { autoClose: 2000 });
        },
        () => {
          toast.error("Error granting certification", {
            autoClose: ERROR_MESSAGE_DURATION,
          });
        },
      );
  };

  const handleRenewCertification = (certID) => {
    api
      .post("/certification-entitlements/grant", {
        userID: parseInt(id),
        certificationID: certID,
      })
      .then(
        () => {
          fetchCertificationEntitlements().then((ce) =>
            setCertificationEntitlements(ce),
          );
          toast.success("Certification renewed", { autoClose: 2000 });
        },
        () => {
          toast.error("Error renewing certification", {
            autoClose: ERROR_MESSAGE_DURATION,
          });
        },
      );
  };

  const handleEnrollUser = (certID) => {
    api
      .post("/certification-entitlements/enroll", {
        userID: parseInt(id),
        certificationID: certID,
      })
      .then(
        () => {
          fetchCertificationEntitlements().then((ce) =>
            setCertificationEntitlements(ce),
          );
          toast.success("User enrolled", { autoClose: 2000 });
        },
        () => {
          toast.error("Error enrolling user", {
            autoClose: ERROR_MESSAGE_DURATION,
          });
        },
      );
  };

  const handleRevokeCertification = (certID) => {
    api
      .post("/certification-entitlements/revoke", {
        userID: parseInt(id),
        certificationID: certID,
      })
      .then(
        () => {
          fetchCertificationEntitlements().then((ce) =>
            setCertificationEntitlements(ce),
          );
          toast.success("Certification revoked", { autoClose: 2000 });
        },
        () => {
          toast.error("Error revoking certification", {
            autoClose: ERROR_MESSAGE_DURATION,
          });
        },
      );
  };

  const handleChangePasswordClick = (e) => {
    e.preventDefault();
    setShowChangePassword(true);
  };

  const handleTerritoryChange = (selected) => {
    if (selected) {
      const filteredSelected = selected.filter((option) => option.value !== 1); // Assuming Unassigned has value = 1
      setSelectedTerritories(
        filteredSelected.length > 0
          ? filteredSelected
          : [{ value: 1, label: "Unassigned" }],
      );
    } else {
      setSelectedTerritories([{ value: 1, label: "Unassigned" }]);
    }
  };

  const mainContent = () => {
    if (showChangePassword) {
      return (
        <ChangePassword
          email={email}
          cancelCallback={() => setShowChangePassword(false)}
          successCallback={() => setShowChangePassword(false)}
          isAdmin={true}
        />
      );
    }

    return (
      <div>
        <div class="row">
          <div class="col">
            <div class="form-container">
              <h4>User Info</h4>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row>
                  <FormGroupInput
                    label="First Name"
                    name="firstName"
                    type="text"
                    value={firstName}
                    placeholder="First Name"
                    onChange={(e) => setFirstName(e.target.value)}
                    required={true}
                    pattern="[a-zA-Z ]+"
                    invalidFeedback="Names may contain alphabetical characters only."
                  />
                  <FormGroupInput
                    label="Last Name"
                    name="lastName"
                    type="text"
                    value={lastName}
                    placeholder="Last Name"
                    onChange={(e) => setLastName(e.target.value)}
                    required={true}
                    pattern="[a-zA-Z ]+"
                    invalidFeedback="Names may contain alphabetical characters only."
                  />
                </Row>
                <Row>
                  <FormGroupInput
                    label="Email"
                    name="email"
                    type="text"
                    value={email}
                    disabled={true}
                  />
                </Row>
                <Row>
                  <Form.Group controlId="password" class="form-group">
                    <Form.Label>Password</Form.Label>
                    <div class="input-group">
                      <Form.Control
                        type="password"
                        value="no peeking this isn't a real password"
                        disabled="true"
                      />
                      <Button
                        className="btn-standard"
                        type="button"
                        onClick={handleChangePasswordClick}
                      >
                        Change Password
                      </Button>
                    </div>
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group controlId="userRole" class="form-group">
                    <RoleDropdown
                      selectedRole={userRoleId}
                      setSelectedRole={(e) => setUserRoleId(e)}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <PhoneNumberInput
                    controlId="phoneNumber"
                    label="Phone Number"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e)}
                    required={false}
                    invalidFeedback="Must provide a phone number in the format 123-456-7890."
                    pattern="^\d{3}-\d{3}-\d{4}$"
                  />
                </Row>
                <Row>
                  <FormGroupInput
                    label="Address"
                    name="address"
                    type="text"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    required={false}
                    invalidFeedback="Must provide an address with allowed special characters .;:#, and allowed spaces."
                    pattern="^[a-zA-Z0-9 .:,-_']+$"
                  />
                </Row>
                <Row>
                  <FormGroupInput
                    label="City"
                    name="city"
                    type="text"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    required={false}
                    invalidFeedback="Must provide a city with allowed special characters .:-_', and allowed spaces."
                    pattern="^[a-zA-Z0-9 .:,-_']+$"
                  />
                </Row>
                <Row>
                  <FormGroupProvinceDropdownInput
                    value={province}
                    required={false}
                    onChange={(e) => setProvince(e.target.value)}
                    placeHolderRequired={false}
                  />
                </Row>
                <Row>
                  <PostalCodeInput
                    controlId="postalCode"
                    label="Postal Code"
                    required={false}
                    type="text"
                    invalidFeedback="Must provide a postal code matching the A1A 1A1 pattern."
                    value={postalCode}
                    onChange={(e) => setPostalCode(e)}
                    pattern="^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$"
                  />
                </Row>
                <Row>
                  <div className="form-group">
                    <label>Agencies</label>
                    <Select
                      isMulti
                      options={territories}
                      value={selectedTerritories}
                      onChange={handleTerritoryChange}
                      placeholder="Select Territories"
                    />
                  </div>
                </Row>
                <Row>
                  <Form.Group controlId="userStatus" class="form-group">
                    <Form.Label>User Status</Form.Label>
                    <Form.Select
                      class="form-select"
                      name="userStatus"
                      value={userStatus}
                      pattern="(Active|Disabled)"
                      onChange={(e) => setUserStatus(e.target.value)}
                      required={true}
                    >
                      {userStatuses.map((status) => (
                        <option key={status.name} value={status.name}>
                          {status.name}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Must select one of the user statuses listed.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group class="form-group">
                    <Button
                      className="btn-standard margin-right-10"
                      type="submit"
                    >
                      Save
                    </Button>
                    <Button
                      className="btn-standard danger"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  </Form.Group>
                </Row>
              </Form>
            </div>
          </div>
          {/*<div class="col">*/}
          {/*  <div class="form-container pb-3">*/}
          {/*    <h4>Certifications</h4>*/}
          {/*    <ul class="list-group">*/}
          {/*      {certifications.map((cert) => (*/}
          {/*        <UserCertification*/}
          {/*          certification={cert}*/}
          {/*          certEntitlements={certificationEntitlements}*/}
          {/*          handleGrantCertificationCallback={handleGrantCertification}*/}
          {/*          handleRenewCertificationCallback={handleRenewCertification}*/}
          {/*          handleEnrollUserCallback={handleEnrollUser}*/}
          {/*          handleRevokeCertificationCallback={*/}
          {/*            handleRevokeCertification*/}
          {/*          }*/}
          {/*        />*/}
          {/*      ))}*/}
          {/*    </ul>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </div>
    );
  };

  return mainContent();
}

export default User;
