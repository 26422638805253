import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
export default function HomeInspectionWaiver({ text }) {
  const [show, setShow] = useState(false);

  const handleOpen = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <>
      <Form.Check
        required
        type="checkbox"
        feedback="You must agree to the terms and conditions before submitting."
        feedbackType="invalid"
        className="-mt-1 mr-2 "
      />
      <span className="flex-1">
        {text}{" "}
        <span
          className="underline cursor-pointer"
          onClick={handleOpen}
        >
          following terms of service
        </span>
        .
      </span>

      {/* Terms and Conditions Modal */}
      <Modal
        className="terms-modal"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Wildfire Mitigation Program Terms of Service
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <h3 className="text-2xl">Hold Harmless Agreement</h3>
        <p>
          By participating in the FireSmart™ BC (FireSmart BC) Wildfire Mitigation Program (WMP), I understand that a Wildfire Mitigation Specialist (WMS) will conduct a wildfire property assessment of my property. Based on the results, the WMS will provide recommendations to reduce wildfire risks. I acknowledge and agree to the following terms:
        </p>
        <h3 className="text-2xl">Right of Entry</h3>
        <p>
          I certify that I am the owner or the owner’s authorized agent of the above-described property, and that I have the legal authority to enter into this Agreement. I grant freely and without coercion the right of access and entry to my property to FireSmart BC, its agents, partner agencies organizations in right of the province of British Columbia (the “Province”) and authorized contractors at a mutually agreed upon date and time to assess my property for the risk of wildfire damage, to develop a customized wildfire mitigation report designed to reduce the risk of wildfire damage, to potentially mark vegetation for removal with paint and to make follow up visits to my property to make sure I am fully prepared for future wildfires.
        </p>
        <h3 className="text-2xl">Reassessment Interval</h3>
        <p>
          I understand that by agreeing to these terms, I authorize the Wildfire Mitigation Program to conduct reassessments of my property as part of the program’s ongoing mitigation efforts within a period of 8 years from the date of this agreement. I will be notified in advance of any reassessment activities, which will be conducted under the same guidelines as the initial assessment.
        </p>
        <h3 className="text-2xl">Release and Hold Harmless</h3>
        <p>
          I understand that this Agreement/Permit does not obligate the Wildfire Mitigation Program to perform any work on my property. I hereby release, waive, discharge, and hold harmless FireSmart, the Canadian Interagency Forest Fire Centre, the Province and the participating local governments, its elected and appointed officials, and its employees, agents and representatives, and Wildfire Mitigation Specialists from all claims, liabilities, damages, losses, or expenses arising out of or related to my participation in the program, including any property damage, bodily injury, death, or other losses. By participating in the FireSmart WMP and by executing this Agreement, it is my express intention and I hereby waive any and all claims which I might have to bring in any form, whether legal or equitable, arising out of my participation in the FireSmart BC Wildfire Mitigation Program and any incidence relating to, but not limited to, the performance of the property assessment, inspection and educational work by any released party herein.
        </p>
        <h3 className="text-2xl">Data Collection and Use</h3>
        <p>
          I acknowledge and agree that the Wildfire Mitigation Program may collect, use, and store data regarding my property to: Develop a customized wildfire mitigation plan. Track the effectiveness of mitigation strategies. Enhance program recommendations and community wildfire resiliency. Assist in qualifying for Government Grants or Rebates awarded for Wildfire Mitigation Efforts.
        </p>
        <h3 className="text-2xl">Forward-Looking Data Collection</h3>
        <p>
          I agree that data collected during the assessment may also be used for: Evaluating long-term program outcomes, and aggregated statistical analysis for reporting and research purposes. The Wildfire Mitigation Program will not share personal data with third parties without my explicit consent, except as required by law. Any data shared will be anonymized to remove personally identifiable information unless otherwise authorized by me.
        </p>
        <h3 className="text-2xl">Disclosure of Property Owner Information</h3>
        <p>
          I understand that the Wildfire Mitigation Program will collect data regarding my property to prepare a customized fire mitigation plan. This data may be shared with provincial or local government or First Nations governments as required to perform these assessments. The FireSmart BC Wildfire Mitigation Program will not disclose to third parties, except as required by law, any personal information they may collect in connection with my participation in the program. FireSmart BC Wildfire Mitigation Program will share program information with program funders as part of normal program reporting. I further acknowledge that FireSmart BC Wildfire Mitigation Program will disclose the name, address and status of program participants to their local fire protection district.
        </p>
        <h3 className="text-2xl">Disclaimer</h3>
        <p>
          I have read and understand that while the Wildfire Mitigation Program aims to reduce the risk of wildfire damage, no recommendations or mitigation efforts can guarantee protection against wildfires. Wildfire mitigation work around your home can give firefighters the best chance to defend and protect your property from wildfires, and can also substantially increase your safety and reduce the risk to life and property. However, wildfire is unpredictable and can be impossible to stop or control, regardless of what mitigation efforts you have taken. By participating in the program, I acknowledge my responsibility to maintain wildfire mitigation measures on my property and to take proactive steps in collaboration with the program. The FireSmart BC Wildfire Mitigation Program makes no warranties, guarantees or representations of any kind with respect to the effectiveness of any mitigation efforts you undertake in connection with your participation in the FireSmart BC WMP.
        </p>
        <p>
          Note: Property Owners who join the WMP may be asked to complete work in the Priority Zones that surround their home, and perform fire-resistant construction retrofits to their home in order to qualify for a Wildfire Mitigation Certificate. These mitigation actions are strictly voluntary but may be required in order to earn a program certificate. Property Owners must seek and attain all required permits and/or HOA/Strata approval before removing trees from their property and/or initiating new construction or alterations to their homes. Preparing for wildfires is an ongoing, long-term effort that requires maintenance and upkeep and does not guarantee home survival. More information on FireSmart Canada guidelines is available at: <a className="underline" href="https://www.firesmartbca.ca" target="_blank" rel="noopener noreferrer">www.firesmartbca.ca</a>.
        </p>
        <h3 className="text-2xl">Acknowledgement and Acceptance</h3>
        <p>
          For additional details on how your data is collected, used, and protected, please refer to our Privacy Policy, available at <a className="underline" href="/privacy-policy" target="_blank">here</a>. By agreeing to these terms, you also acknowledge and accept the terms of the Privacy Policy.
        </p>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn-black btn-standard"
            onClick={handleClose}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}