import React from "react";
import SupportPageTemplate from "./SupportPageTemplate";

function HomeownerSupportPage() {

    const title = "Homeowner Support";
    const paragraph = "Need help with something? Check out our frequently asked questions below.";

    const faqs = [
      { question: "How can I request an assessment?", answer: "Homeowners or authorized individuals can request an assessment through the FireSmart Request A Home Assessment page found <a className=\"underline\" href=\"https://firesmartbcapp.ca/request-home-inspection\" target=\"_blank\">here</a>. Alternatively, requestors can contact their regional coordinator or submit a request via the FireSmart BC platform." },
      { question: "How can I request a reassessment after I’ve done mitigation work?", answer: "Assessment participants can submit a reassessment request via the FireSmart BC platform using the access code that was emailed to them. Alternatively, participants can contact the WMS (Wildfire Mitigation Specialist) that performed their initial assessment to initiate a reassessment request on their behalf." },
      { question: "What are the steps for a home to achieve FireSmart certification under WMP?", answer: "Step 1. An assessment is requested. Step 2. A Wildfire Mitigation Specialist (WMS) conducts the assessment at an agreed upon time. Step 3. The client receives a customized mitigation plan via email, in person, or lettermail. Step 4. The client completes recommendations. Step 5. The client requests reassessment. Step 6. The WMS verifies completed work and either provides additional mitigation suggestions, or submits the assessment to the local FireSmart Program Coordinator for certification if all mitigation efforts have been completed." },
      { question: "What happens if a homeowner cannot complete the recommendations outlined in the WMP assessment?", answer: "Recommendations are voluntary. Clients are encouraged to implement as many as possible but are not penalized for incomplete work." },
      { question: "How can I confirm that an assessment has been requested for my property?", answer: "Clients will receive an email notification when an assessment is requested for their property. You can confirm participation by following the link provided in the email and logging into your dashboard with the provided access code." },
      { question: "What happens to my data if I participate in the assessment?", answer: "Your data is securely stored in compliance with privacy regulations and used to prepare your wildfire mitigation report. For more details, refer to the <a className=\"underline\" href=\"https://firesmartbcapp.ca/privacy-policy\" target=\"_blank\">Privacy Policy</a>." },
      { question: "Is my privacy policy identical to the previous Home Partners Program?", answer: "While the policies align in terms of data protection, the FireSmart BC platform has updated privacy measures. The current Privacy Policy ensures your data is secure and outlines its usage." },
      { question: "Will my completed assessment qualify me for insurance discounts?", answer: "Certain insurance providers may offer discounts based on completed assessments. Please review our insurance page located <a className=\"underline\" href=\"https://firesmartbc.ca/wmp/insurance/\" target=\"_blank\">here</a> for more information." },
      { question: "How can I track my progress in completing FireSmart recommendations?", answer: "Clients can log into the platform to view their assessment reports and progress on implementing FireSmart recommendations." },
      { question: "If I move, can my assessment transfer to the new homeowner?", answer: "No, assessments are tied to the property and cannot transfer between owners or authorized individuals. A new assessment will have to be scheduled." },
      { question: "What does FireSmart 'certified' mean?", answer: "FireSmart certification indicates that a property has been assessed and found to meet specific wildfire mitigation standards. Certification ensures that clients have taken all steps to reduce wildfire risks to their homes." },
      { question: "Do homes need to be reassessed to remain certified?", answer: "Yes, reassessments are recommended every seven years to ensure continued adherence to FireSmart standards and account for any new wildfire risks." },
      { question: "What are the challenges faced with getting homes certified?", answer: "Challenges include clients' time and financial constraints, access to resources for implementing recommendations, and lack of awareness about wildfire mitigation." },
      { question: "What is a Recognized Neighbourhood?", answer: "A Recognized Neighbourhood is a community that has worked together to conduct a Neighbourhood Wildfire Hazard Assessment to mitigate wildfire risks collectively." },
      { question: "Will insurance carriers give a discount to homeowners who are FireSmart certified or who complete assessment recommendations?", answer: "Currently, BCAA and Cooperators offer discounts to clients with FireSmart certifications. Additional carriers may join at any time. Stay up to date by periodically checking the insurance page on our website." },
      { question: "Can real estate home inspectors complete FireSmart Assessments as an add-on fee and use the FireSmart brand on real estate listings?", answer: "No, FireSmart Assessments must be conducted by certified Wildfire Mitigation Specialists (WMS). Unauthorized use of the FireSmart brand is not permitted." },
      { question: "If a FireSmart home and property assessment is completed and the homeowner cannot complete the suggested FireSmart recommendations, does it have a negative impact on the homeowner’s insurance?", answer: "No, incomplete recommendations do not negatively impact insurance. FireSmart recommendations are voluntary." },
      { question: "Will the WMP provide a Fire Safety Report on homes if requested by insurance carriers?", answer: "FireSmart BC does not generate reports for insurance purposes. However, clients may share their FireSmart assessments directly with their insurers." },
      { question: "Is there funding available for homeowners who complete FireSmart activities on their property?", answer: "Yes, some regional districts, First Nations, and municipalities offer rebates funded by the Community Resiliency Investment (CRI) Program for clients who complete FireSmart activities." },
      { question: "Are FireSmart Community Funding & Supports only allotted to First Nations, Regional Districts, and Municipalities, or can homeowners apply for the funding?", answer: "Funding is allotted to local governments and First Nations. Homeowners can access rebates and incentives through their local authorities." },
      { question: "Is there a cost to homeowners for having a FireSmart assessment completed?", answer: "No, FireSmart assessments are provided free of charge under the WMP." },
      { question: "How does a Wildfire Mitigation assessment work on rural farmland? Would it apply to the main residential structure or the property as a whole?", answer: "Assessments focus on the main residential structure and its immediate surrounding zones. Additional recommendations may be provided for other areas of the property. For further FireSmart recommendations on farmland, visit the FireSmart Farm and Ranch Program <a className=\"underline\" href=\"https://firesmartbc.ca/farm-and-ranch-wildfire-preparedness/\" target=\"_blank\">here</a>." },
      { question: "Are there specific protocols for managing FireSmart assessments on rural commercial farmland?", answer: "Yes, we currently have three Residential assessment types: Home Ignition Zone (HIZ), Critical Infrastructure, and Wildfire Mitigation Program. There is also a Farm and Ranch assessment that is a Ministry of Agriculture program. For more information about the Farm & Ranch program click <a className=\"underline\" href=\"https://firesmartbc.ca/farm-and-ranch-wildfire-preparedness/\" target=\"_blank\">here</a>. Commercial property specific assessments are not currently available." },
      { 
        question: "What is the process for a Neighbourhood Recognition application?", 
        answer: `
          <ol class="list-decimal list-inside">
            <li>Create an account in the FireSmart Canada NRP Web Portal</li>
            <li>Form a FireSmart Neighbourhood Committee</li>
            <li>LFR/NRP Specialist Conducts Neighbourhood Wildland Fire Hazard Assessment</li>
            <li>Create a FireSmart Neighbourhood Mitigation Plan</li>
            <li>Implement FireSmart Events</li>
            <li>Apply for Recognition</li>
            <li>Renew your Recognition Status</li>
          </ol>
          More information can be found <a class="underline" href="https://firesmartcanada.ca/programs/neighbourhood-recognition-program/" target="_blank">here</a>.
        ` 
      },
      { question: "Do neighbourhoods have to finish implementing the strategies before they are recognized?", answer: "While neighborhoods do not have to complete every action item listed in their 5 year Neighborhood Plan, they do have to show that progress is being made each year through FireSmart activities." },
      { question: "Do neighbourhoods need to maintain a certain standard to remain recognized?", answer: "Yes, all neighborhoods must renew their recognition annually to maintain their status. Recognition includes updating documents, completing required FireSmart activities, and applying for recognition through the Portal." },
      { question: "What is the expected outcome for WMP certification by 2025?", answer: "The goal is to provide an easy to follow, free and voluntary work plan for the resident to work towards certification. By increasing the number of homes on the path to certification - or fully certified - wildfire risk is reduced incrementally." }
  ];

    return (
        <SupportPageTemplate title={title} paragraph={paragraph} questions={faqs} />
    );
}

export default HomeownerSupportPage;