import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../shared/utils/api";
import { toast } from "react-toastify";
import FormGroupInput from "../shared/components/FormGroupInput";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { ERROR_MESSAGE_DURATION } from "../shared/Constants";

function ForgotPassword() {
  const [validated, setValidated] = useState(false);
  const [email, setEmail] = useState("");
  const [verifictionCode, setVerificationCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [resetPending, setResetPending] = useState(false);

  const navigate = useNavigate();

  const handleCancel = (e) => {
    e.preventDefault();
    navigate("/login");
  };

  const handleConfirmSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      return; // Return if validation failed
    }

    api
      .post("/confirm-reset-password", {
        email: email.trim().toLowerCase(),
        verificationCode: verifictionCode,
        password: newPassword,
      })
      .then(
        (response) => {
          toast.success("Password reset successful.", { autoClose: 5000 });
          navigate("/login");
        },
        (error) => {
          toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
        },
      );
  };

  const handleResetSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      return; // Return if validation failed
    }

    setValidated(true);

    api
      .post("/reset-password", {
        email: email.trim().toLowerCase(),
      })
      .then(
        (response) => {
          toast.success("Password reset email sent.", { autoClose: 5000 });
          setResetPending(true);
        },
        (error) => {
          toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
        },
      );
  };

  const mainContent = () => {
    if (resetPending) {
      return (
        <div className="form-container center">
          <h4>Confirm Reset Password</h4>
          <Form noValidate validated={validated} onSubmit={handleConfirmSubmit}>
            <Row>
              <FormGroupInput
                controlId="email"
                label="Email"
                type="text"
                onChange={(e) => setEmail(e.target.value)}
                required={true}
                invalidFeedback="Please enter a valid email."
              />
            </Row>

            <Row>
              <FormGroupInput
                controlId="verificationCode"
                label="Verification Code"
                type="text"
                onChange={(e) => setVerificationCode(e.target.value)}
                required={true}
                invalidFeedback="Please enter a valid verification code."
              />
            </Row>

            <Row>
              <FormGroupInput
                controlId="newPassword"
                label="New Password"
                type="password"
                onChange={(e) => setNewPassword(e.target.value)}
                required={true}
                invalidFeedback="Please enter a valid password."
              />
            </Row>

            <Row>
              <Form.Group class="form-group">
                <Button className="btn-standard margin-right-10" type="submit">
                  Reset Password
                </Button>
                <Button className="btn-standard danger" onClick={handleCancel}>
                  Cancel
                </Button>
              </Form.Group>
            </Row>
          </Form>
        </div>
      );
    }
    return (
      <div className="form-container center">
        <h4>Reset Password</h4>
        <Form noValidate validated={validated} onSubmit={handleResetSubmit}>
          <Row>
            <FormGroupInput
              controlId="email"
              label="Email"
              type="text"
              onChange={(e) => setEmail(e.target.value)}
              required={true}
              invalidFeedback="Please enter a valid email."
            />
          </Row>

          <Row>
            <Form.Group class="form-group">
              <Button className="btn-standard margin-right-10" type="submit">
                Reset Password
              </Button>
              <Button className="btn-standard danger" onClick={handleCancel}>
                Cancel
              </Button>
            </Form.Group>
          </Row>
        </Form>
      </div>
    );
  };

  return mainContent();
}

export default ForgotPassword;
