import React, { useEffect, useState } from "react";
import api from "../shared/utils/api";
import { toast } from "react-toastify";
import { ERROR_MESSAGE_DURATION } from "../shared/Constants";
import Form from "react-bootstrap/Form";
import { getUserRole } from "../shared/utils/authToken";

function RoleDropdown({ selectedRole, setSelectedRole, disabled = false }) {
  const [roles, setRoles] = useState([]);
  const [dropDownDisabled, setDropDownDisabled] = useState(disabled);

  const fetchRoles = () => {
    api.get("/fireSmartRoles").then(
      (response) => {
        if (response === null) return;
        if (response === undefined) return;
        setRoles(response);
      },
      (error) => {
        toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
      },
    );
  };

  useEffect(() => {
    if (!disabled) {
      setDropDownDisabled(getUserRole() !== 1);
    }
    fetchRoles();
  }, []);

  return (
    <div>
      <Form.Label className="font-bold">User Role</Form.Label>
      <Form.Select
        class="form-select"
        name="userRole"
        value={selectedRole}
        pattern="(Active|Disabled)"
        onChange={(e) => setSelectedRole(e.target.value)}
        disabled={dropDownDisabled}
        required
      >
        {roles.map((role) => {
          return (
            <option key={role.roleID} value={role.roleID}>
              {role.roleName}
            </option>
          );
        })}
      </Form.Select>
    </div>
  );
}

export default RoleDropdown;
