import React, { useState, useEffect } from 'react';

function PostalCodeInput({
    controlId,
    label,
    value,
    onChange,
    required,
    invalidFeedback,
    pattern = "^[A-Za-z]\\d[A-Za-z] \\d[A-Za-z]\\d$",
    ...props
}) {
    const [postalCode, setPostalCode] = useState(value || '');
    
    useEffect(() => {
        if (value && value.length === 7) {
            // If the value is already in the correct format (7 characters including space), set it as is
            setPostalCode(value.toUpperCase());
        } else if (value && value.length === 6) {
            // If the value is missing the space, add it
            const formattedValue = value.toUpperCase().slice(0, 3) + ' ' + value.slice(3);
            setPostalCode(formattedValue);
        }
    }, [value]);

    const handleInputChange = (e) => {
        let inputValue = e.target.value.toUpperCase().replace(/\s/g, ''); // Remove spaces and convert to uppercase

        if (inputValue.length > 6) return; // Restrict length to 6 characters (excluding the space)

        // Validate each character based on its position (odd = letter, even = digit)
        let isValid = true;
        for (let i = 0; i < inputValue.length; i++) {
            if (i % 2 === 0) {
                if (!/[A-Za-z]/.test(inputValue[i])) {
                    isValid = false;
                    break;
                }
            } else {
                if (!/\d/.test(inputValue[i])) {
                    isValid = false;
                    break;
                }
            }
        }

        if (isValid) {
            // Automatically insert a space after the 3rd character
            if (inputValue.length === 6) {
                inputValue = inputValue.slice(0, 3) + ' ' + inputValue.slice(3);
            }
            setPostalCode(inputValue);
            if (onChange) onChange(inputValue); // Pass the formatted value back to the parent component
        }
    };

    return (
        <div className="form-group">
            {label && <label className="font-bold form-label" htmlFor={controlId}>{label}</label>}
            <input
                type="text"
                id={controlId}
                value={postalCode}
                onChange={handleInputChange}
                required={required}
                pattern={pattern}
                className="form-control"
                {...props}
            />
            {invalidFeedback && (
                <div className="invalid-feedback">
                    {invalidFeedback}
                </div>
            )}
        </div>
    );
}

export default PostalCodeInput;