import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { getStoredAuthToken } from './authToken';

export default function ProtectedRoute(props) {
    const isAuthenticated = getStoredAuthToken() !== undefined;
    const location = useLocation();

    return (
        isAuthenticated ? props.children : <Navigate to={{ pathname: '/home', state: { from: location } }} />
    );
}
