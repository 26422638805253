
import { storeAuthToken } from '../shared/utils/authToken';
import api from '../shared/utils/api';

// This component is used to refresh the user token
function TokenRefresh() {
   api.get('/token-refresh').then(
       (response) => {
           storeAuthToken(response.token);
       },
       (error) => {
           // TODO: this currently silently errors as nav bar header state with the user's profile
           //       name is not shared between the relevant components. This should be fixed.
       }
   )
};

export default TokenRefresh;
