import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Row, Col, Button, Modal } from "react-bootstrap";
import { LuPlusCircle } from "react-icons/lu";
import { FileSpreadsheet, Calendar, X } from "lucide-react";
import { CgMoreO } from "react-icons/cg";
import { CiCircleList } from "react-icons/ci";



import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  flexRender,
} from "@tanstack/react-table";
import api from "../../shared/utils/api";
import { toast } from "react-toastify";
import { ERROR_MESSAGE_DURATION } from "../../shared/Constants";

import { ColumnHeader } from "../Users";
import {
  format,
  parseISO,
} from "date-fns";
import { isUserAdmin } from "../../shared/utils/authToken";

import * as XLSX from "xlsx";

const exportToExcel = (table) => {
  const filteredRows = table.getFilteredRowModel().rows;

  const exportData = filteredRows.map((row) => {
    const rowData = row.getVisibleCells().reduce((acc, cell) => {
      const columnHeader = cell.column.columnDef.header; 
      const cellValue = cell.getValue();

      if (columnHeader.toLowerCase().includes("date") && cellValue) {
        const parsedDate = new Date(cellValue);
        acc[columnHeader] = !isNaN(parsedDate)
          ? parsedDate.toLocaleDateString("en-US", { 
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
          : cellValue;
      } else if (cell.column.columnDef.accessorKey === "agencyNames" && cellValue) {
        acc[columnHeader] = cellValue.split(",").join(", ");
      } else {
        acc[columnHeader] = cellValue;
      }

      return acc;
    }, {});
    return rowData;
  });

  // Create a new workbook and worksheet
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(exportData);

  // Add header row styling
  ws["!rows"] = ws["!rows"] || [];
  ws["!rows"].push({ hpt: 20 }); // Increase header row height

  // Bold and center header row
  const headerRange = XLSX.utils.decode_range(ws["!ref"]);
  for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
    const headerCell = XLSX.utils.encode_cell({ r: 0, c: col });
    ws[headerCell].s = {
      font: { bold: true },
      alignment: { horizontal: "center", vertical: "center" },
    };
  }

  // Add footer rows
  const footerRows = [
    { "": "Generated by FireSmart BC" },
    { "": `Generated on: ${new Date().toLocaleString()}` },
    { "": `Total Assessments: ${filteredRows.length}` },
  ];

  // Append footer rows
  XLSX.utils.sheet_add_json(ws, footerRows, {
    origin: -1, // Append at the end
    skipHeader: false, // Don't add another header
  });

  // Adjust column widths
  const colWidths = Object.keys(exportData[0] || {}).map((key) => {
    const headerLength = key.length;
    const maxDataLength = Math.max(
      ...exportData.map((row) => String(row[key] || "").length),
      headerLength,
    );
    return Math.min(Math.max(headerLength, maxDataLength) + 2, 30);
  });

  // Apply column widths
  ws["!cols"] = colWidths.map((width) => ({ wch: width }));

  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(wb, ws, "Users");

  // Export the Excel file
  XLSX.writeFile(
    wb,
    `FireSmart_Assessments_${new Date().toISOString().split("T")[0]}.xlsx`,
  );
};

const QuickDateFilter = ({ onFilterChange, activeFilter }) => {
  const quickFilters = [
    {
      key: "thisMonth",
      label: "This Month",
      getFilter: () => ({
        type: "thisMonth",
        start: format(new Date(), "yyyy-MM-dd"),
        end: format(new Date(), "yyyy-MM-dd"),
      }),
    },
    {
      key: "lastMonth",
      label: "Last Month",
      getFilter: () => {
        const lastMonth = new Date(
          new Date().getFullYear(),
          new Date().getMonth() - 1,
          1,
        );
        const lastMonthEnd = new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          0,
        );
        return {
          type: "lastMonth",
          start: format(lastMonth, "yyyy-MM-dd"),
          end: format(lastMonthEnd, "yyyy-MM-dd"),
        };
      },
    },
    {
      key: "thisYear",
      label: "This Year",
      getFilter: () => {
        const thisYear = new Date().getFullYear();
        return {
          type: "thisYear",
          start: `${thisYear}-01-01`,
          end: `${thisYear}-12-31`,
        };
      },
    },
    {
      key: "lastYear",
      label: "Last Year",
      getFilter: () => {
        const lastYear = new Date().getFullYear() - 1;
        return {
          type: "lastYear",
          start: `${lastYear}-01-01`,
          end: `${lastYear}-12-31`,
        };
      },
    },
    {
      key: "last30Days",
      label: "Last 30 Days",
      getFilter: () => {
        const today = new Date();
        const thirtyDaysAgo = new Date(today);
        thirtyDaysAgo.setDate(today.getDate() - 30);
        return {
          type: "last30Days",
          start: format(thirtyDaysAgo, "yyyy-MM-dd"),
          end: format(today, "yyyy-MM-dd"),
        };
      },
    },
    {
      key: "all",
      label: "All Dates",
      getFilter: () => null,
    },
  ];

  const [isCustomDateOpen, setIsCustomDateOpen] = useState(false);
  const [customStartDate, setCustomStartDate] = useState("");
  const [customEndDate, setCustomEndDate] = useState("");

  const handleQuickFilter = (filter) => {
    // If the current filter is already active, turn it off
    if (activeFilter?.type === filter.key) {
      onFilterChange(null);
      return;
    }

    onFilterChange(filter.getFilter());
  };

  const handleCustomDateApply = () => {
    if (!customStartDate || !customEndDate) {
      toast.error("Please select both start and end dates");
      return;
    }

    onFilterChange({
      type: "custom",
      start: customStartDate,
      end: customEndDate,
    });
    setIsCustomDateOpen(false);
  };

  const clearFilter = () => {
    setCustomStartDate("");
    setCustomEndDate("");
    onFilterChange(null);
    setIsCustomDateOpen(false);
  };

  // Format the active filter for display
  const getActiveFilterLabel = () => {
    if (!activeFilter) return null;

    // For predefined quick filters
    const quickFilter = quickFilters.find((f) => f.key === activeFilter.type);
    if (quickFilter) return quickFilter.label;

    // For custom date range
    if (activeFilter.type === "custom") {
      try {
        const start = parseISO(activeFilter.start);
        const end = parseISO(activeFilter.end);
        return `${format(start, "MMM d, yyyy")} - ${format(end, "MMM d, yyyy")}`;
      } catch {
        return "Custom Date Range";
      }
    }

    return null;
  };

  const activeFilterLabel = getActiveFilterLabel();

  return (
    <div className="w-full bg-gray-50 rounded-lg py-4">
      <div className="flex flex-row justify-between space-y-2">
        {/* Active Filter Display */}

        {/* Filter Buttons */}
        <div className="flex flex-wrap items-center gap-2">
          {quickFilters.map((filter) => (
            <button
              key={filter.key}
              onClick={() => handleQuickFilter(filter)}
              className={`
                px-3 py-1.5 pt-2 rounded-full text-sm font-medium transition-all duration-200 ease-in-out border-border border-1
                ${
                  activeFilter?.type === filter.key
                    ? "bg-slate text-white hover:bg-slate border-slate"
                    : "text-slate hover:bg-gray-200"
                }
              `}
            >
              {filter.label}
              {activeFilter?.type === filter.key && (
                <X className="ml-2 h-4 w-4 inline-block" />
              )}
            </button>
          ))}

          <div className="relative">
            <button
              onClick={() => setIsCustomDateOpen(!isCustomDateOpen)}
              className={`
                px-3 py-1.5 pt-2 flex items-center px-3 py-1.5 rounded-full text-sm font-medium transition-all duration-200 ease-in-out border-border border-1
                ${
                  isCustomDateOpen
                    ? "bg-slate text-white hover:bg-slate border-slate"
                    : "text-slate hover:bg-gray-200"
                }
              `}
            >
              <Calendar className="mr-2 -mt-1 h-4 w-4" /> Custom
            </button>

            {isCustomDateOpen && (
              <div className="absolute right-0 z-50 mt-2 w-80 bg-white rounded-lg shadow-lg border border-gray-200 p-6">
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Start Date
                    </label>
                    <input
                      type="date"
                      value={customStartDate}
                      onChange={(e) => setCustomStartDate(e.target.value)}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      End Date
                    </label>
                    <input
                      type="date"
                      value={customEndDate}
                      onChange={(e) => setCustomEndDate(e.target.value)}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                  </div>
                  <div className="flex justify-between items-center space-x-2">
                    <button
                      onClick={clearFilter}
                      className="flex items-center text-sm text-gray-600 hover:text-gray-900"
                    >
                      <X className="mr-1 h-4 w-4" /> Clear
                    </button>
                    <div className="flex space-x-2">
                      <button
                        onClick={() => setIsCustomDateOpen(false)}
                        className="px-3 py-2 text-sm text-gray-700 btn btn-outline btn-standard"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={handleCustomDateApply}
                        className="px-3 py-2 text-sm btn btn-black btn-standard "
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      
      </div>
    </div>
  );
};

const statusConfig = {
  REQUESTED: {
    name: "Requested",
    color: "bg-blue-200 text-blue-900",
    dotClass: "bg-blue-700",
    description: "When a new assessment has been requested but not yet assigned.",
  }, 
  ASSIGNED: {
    name: "Assigned",
    color: "bg-purple-200 text-purple-900",
    dotClass: "bg-purple-700",
    description: "When an assessment has been assigned but not yet started.",
  },
  IN_PROGRESS: {
    name: "In Progress",
    color: "bg-orange-200 text-orange-900",
    dotClass: "bg-orange-700",
    description: "When an assessment has been started but not yet submitted for approval.",
  },
 
  APPROVAL_REQUIRED: {
    name: "Approval Required",
    color: "bg-indigo-200 text-indigo-900",
    dotClass: "bg-indigo-700",
    description: " When an assessment has been submitted for coordinator review.",
  },
  CHANGES_REQUESTED: {
    name: "Changes Requested",
    color: "bg-pink-200 text-pink-900",
    dotClass: "bg-pink-700",
    description: "When a coordinator requests changes to the assessment to be completed by the assessor.",
  },
  DELIVERED: {
    name: "Delivered",
    color: "bg-green-100 text-green-600",
    dotClass: "bg-green-400",
    description: "When an assessment has been approved and sent to the requesting party.",
  },
  REINSPECTION: {
    name: "Re-Inspection",
    color: "bg-indigo-300 text-indigo-800",
    dotClass: "bg-indigo-700",
    description: "When the requesting party has completed recommended mitigation efforts and requests a re-inspection.",
  },
 
  COMPLETED: {
    name: "Completed",
    color: "bg-green-300 text-green-900",
    dotClass: "bg-green-600",
    description: "When an assessment has been approved by a coordinator with no mitigative steps remaining.",
  },

  CERTIFIED: {
    name: "Certified",
    color: "bg-green-400 text-green-900",
    dotClass: "bg-green-700",
    description: " When a completed assessment has been reviewed and approved by a program manager. The home has received Wildfire Mitigation Program certification!",
  },
  CANCELLED: {
    name: "Cancelled",
    color: "bg-red-200 text-red-900",
    dotClass: "bg-red-700",
    description: "When an assessment has been cancelled.",
  },
  DEFAULT: {
    name: "Unknown",
    color: "bg-indigo-300 text-indigo-800",
    dotClass: "bg-indigo-700",
    description: "Unknown status.",
  },
};

const getBadge = (status) => {
  const baseClasses = "text-sm px-3 pt-2 pb-1 rounded-full";
  const statusData =
    Object.values(statusConfig).find((s) => s.name === status) ||
    statusConfig.DEFAULT;
  return {
    badgeClass: `${baseClasses} ${statusData.color}`,
    dotClass: statusData.dotClass,
  };
};

function HomeInspections() {
  const [homeInspections, setHomeInspections] = useState([]);
  const [agencies, setAgencies] = useState([]);
  const [dateFilter, setDateFilter] = useState(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [showStatusesModal, setShowStatusesModal] = useState(false);
  const handleShowStatusesModal = () => setShowStatusesModal(true);
  const handleCloseStatusesModal = () => setShowStatusesModal(false);

  // Modify the date filtering logic
  const filterHomeInspectionsByDate = (inspections, filter) => {
    if (!filter) return inspections;

    return inspections.filter((inspection) => {
      if (!inspection.creationDate) return false;

      const creationDate = parseISO(inspection.creationDate);

      // No filter or 'all' filter
      if (!filter || filter.type === "all") return true;

      // Quick filters
      if (filter.type !== "custom") {
        const today = new Date();

        switch (filter.type) {
          case "thisMonth":
            return (
              creationDate.getMonth() === today.getMonth() &&
              creationDate.getFullYear() === today.getFullYear()
            );

          case "lastMonth": {
            const lastMonth = new Date(
              today.getFullYear(),
              today.getMonth() - 1,
              1,
            );
            const lastMonthEnd = new Date(
              today.getFullYear(),
              today.getMonth(),
              0,
            );
            return creationDate >= lastMonth && creationDate <= lastMonthEnd;
          }

          case "thisYear":
            return creationDate.getFullYear() === today.getFullYear();

          case "lastYear":
            return creationDate.getFullYear() === today.getFullYear() - 1;

          case "last30Days": {
            const thirtyDaysAgo = new Date(today);
            thirtyDaysAgo.setDate(today.getDate() - 30);
            return creationDate >= thirtyDaysAgo && creationDate <= today;
          }
        }
      }

      // Custom date range filter
      if (filter.type === "custom") {
        const startDate = parseISO(filter.start);
        const endDate = parseISO(filter.end);

        // Adjust end date to end of day
        endDate.setHours(23, 59, 59, 999);

        return creationDate >= startDate && creationDate <= endDate;
      }

      return true;
    });
  };

  // Fetch home inspections with date filtering
  useEffect(() => {
    const fetchHomeInspections = () => {
      api.get("/home-inspections").then(
        (response) => {
          if (response) {
            const filteredInspections = dateFilter
              ? filterHomeInspectionsByDate(response, dateFilter)
              : response;
            setHomeInspections(filteredInspections);
            console.log(
              "Fetched and Filtered Home Inspections:",
              filteredInspections,
            );
          }
        },
        (error) => {
          toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
        },
      );
    };
    fetchHomeInspections();
  }, [dateFilter]);

  useEffect(() => {
    const territoryURL = isUserAdmin()
      ? "/territories"
      : "/territories-for-user";
    api.get(territoryURL).then(
      (response) => {
        const agencyOptions = response
          .filter((territory) => territory.id !== 1)
          .map((territory) => ({
            value: territory.name,
            label: territory.name,
          }));
        setAgencies(agencyOptions);
      },
      (error) => {
        toast.error("Failed to load territories. Error: " + error, {
          autoClose: ERROR_MESSAGE_DURATION,
        });
      },
    );
  }, []);


  // Filtering function
  const filterFnLocal = (row, columnId, filterValue) => {
    if (
      !filterValue ||
      (Array.isArray(filterValue) && filterValue.length === 0)
    ) {
      return true;
    }

    const cellValue = row.getValue(columnId);

    if (Array.isArray(filterValue)) {
      // Handle 'select' and 'multiSelect' filters
      if (columnId === "agencyName") {
        const agencyList = (cellValue || "").split(",").map((v) => v.trim());
        return filterValue.some((selectedAgency) =>
          agencyList.includes(selectedAgency),
        );
      } else {
        // For other columns like 'homeInspectionStatus'
        return filterValue.includes(cellValue);
      }
    } else {
      const { operator, value } = filterValue;
      if (operator && value !== undefined && value !== null) {
        const cellValueStr = String(cellValue || "").toLowerCase();
        const filterValueStr = String(value).toLowerCase();
        switch (operator) {
          case "contains":
            return cellValueStr.includes(filterValueStr);
          case "equals":
            return cellValueStr === filterValueStr;
          case "startsWith":
            return cellValueStr.startsWith(filterValueStr);
          case "endsWith":
            return cellValueStr.endsWith(filterValueStr);
          case "gt":
            return Number(cellValue) > Number(value);
          case "gte":
            return Number(cellValue) >= Number(value);
          case "lt":
            return Number(cellValue) < Number(value);
          case "lte":
            return Number(cellValue) <= Number(value);
          default:
            return true;
        }
      }
      return true;
    }
  };

  // Columns definition
  const columns = useMemo(
    () => [
      {
        accessorKey: "creationDate",
        header: "Req. Date",
        type: "date",
        cell: ({ getValue }) => {
          const date = getValue();
          return date
            ? new Date(date).toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                day: "numeric",
              })
            : "";
        },
      },
      {
        accessorKey: "homeOwnerName",
        header: "Homeowner Name",
        type: "text",
        filterFn: filterFnLocal
      },
      {
        accessorKey: "address",
        header: "Address",
        type: "text",
        filterFn: filterFnLocal,
      },
      {
        accessorKey: "agencyName",
        header: "Agency",
        type: "multiSelect",
        options: agencies,
        cell: ({ getValue }) => {
          const agencies = getValue();
          return agencies ? (
            <div className="flex flex-wrap gap-1">
              {agencies.split(",").map((agency, index) => (
                <span
                  key={index}
                  className="bg-gray-100 px-2 py-1 rounded text-sm"
                >
                  {agency.trim()}
                </span>
              ))}
            </div>
          ) : null;
        },
        filterFn: filterFnLocal,
      },
      {
        accessorKey: "assignedInspectorFirstName",
        header: "Assessor",
        type: "text",
        cell: ({ row }) => {
          const firstName = row.original.assignedInspectorFirstName;
          const lastName = row.original.assignedInspectorLastName;
          return firstName
            ? `${firstName} ${lastName || ""}`.trim()
            : "Unassigned";
        },
        filterFn: filterFnLocal,
      },
      {
        accessorKey: "auditTitle",
        header: "Type",
        type: "text",
        filterFn: filterFnLocal,
      },
      {
        accessorKey: "homeInspectionStatus",
        header: "Status",
        type: "select",
        options: [
          "Requested",
          "Assigned",
          "In Progress",
          "Changes Requested",
          "Changes Submitted",
          "Approval Required",
          "Completed",
          "Cancelled",
        ].map((status) => ({ value: status, label: status })),
        cell: ({ getValue }) => {
          const status = getValue();
          const { badgeClass, dotClass } = getBadge(status);

          return (
            <span className={`inline-flex items-center ${badgeClass}`}>
              <span className={`inline-block w-2 h-2 -mt-1 mr-2 rounded-full ${dotClass}`}></span>
              {status}
            </span>
          );
        },
        filterFn: filterFnLocal,
      },
    ],
    [agencies],
  );

  // Create table instance
  const table = useReactTable({
    data: homeInspections,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    initialState: {
      pagination: {
        pageSize: 10,
      },
    },
  });

  return (
    <div className="pt-2">
      {/* Header */}
      <Row className="mb-4">
        <Col md={4}>
          <h2>FireSmart Assessments</h2>
        </Col>
        <Col md={8} className="md:text-right col-lg space-x-2 space-y-2">
        <button className=" btn !font-bold btn-standard btn-outline mt-2"  onClick={handleShowStatusesModal}><CiCircleList className="mr-1 -mt-0.5 h-4 w-4 inline-block" /> Statuses Overview</button>
        <button
            className="btn-standard btn-outline btn"
            onClick={() => exportToExcel(table, "Assessments")}
          >
            <FileSpreadsheet className="mr-1 -mt-0.5 h-4 w-4 inline-block" />{" "}
            Export to Excel
          </button>
          <span className="hidden md:inline-block text-gray-600">|</span>
          <button className="btn-standard btn" onClick={() => navigate("add")}>
            <LuPlusCircle className="mr-1 -mt-0.5 h-4 w-4 inline-block" /> New
            Assessment
          </button>
          
        </Col>
      </Row>

      {/* Quick Date Filter */}
      <QuickDateFilter
        onFilterChange={(filter) => {
          setDateFilter(filter);
        }}
        activeFilter={dateFilter}
      />

      {/* Table */}
      <table className="min-w-full divide-y table" id="assessments-table">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="px-3 xl:px-4 py-2 text-left text-xs font-bold relative min-w-[120px]"
                >
                  {header.isPlaceholder ? null : (
                    <ColumnHeader column={header.column} />
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="divide-y divide-gray-200">
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              onClick={() =>
                navigate(
                  "/home-inspection/" +
                    row.original.homeInspectionID.toString(),
                )
              }
              className="hover:bg-gray-100 cursor-pointer"
            >
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="px-3 xl:px-4 py-2 ">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination Controls */}
      <div className="flex items-center justify-between mt-4">
        <div className="flex items-center">
          <button
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
            className="px-3 py-1 border rounded-l disabled:opacity-50"
          >
            {"<<"}
          </button>
          <button
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
            className="px-3 py-1 border-t border-b disabled:opacity-50"
          >
            {"<"}
          </button>
          <button
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
            className="px-3 py-1 border-t border-b disabled:opacity-50"
          >
            {">"}
          </button>
          <button
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
            className="px-3 py-1 border rounded-r disabled:opacity-50"
          >
            {">>"}
          </button>
          <span className="ml-2">
            Page{" "}
            <strong>
              {table.getState().pagination.pageIndex + 1} of{" "}
              {table.getPageCount()}
            </strong>
          </span>
        </div>
        <div>
          <select
            value={table.getState().pagination.pageSize}
            onChange={(e) => table.setPageSize(Number(e.target.value))}
            className="ml-4 border p-1 rounded"
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>
      <StatusesModal
        show={showStatusesModal}
        handleClose={handleCloseStatusesModal}
      />
    </div>
  );
}

export default HomeInspections;


const StatusesModal = ({ show, handleClose }) => {
  return (
    <Modal show={show} onHide={handleClose} dialogClassName="modal-lg"> {/* Use Bootstrap's modal-lg class for wider modal */}
      <Modal.Header closeButton>
        <Modal.Title>Status Overview</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {Object.values(statusConfig)
          .filter((status) => status.name !== "Unknown") // Exclude "Unknown" status
          .map((status) => {
            const { badgeClass, dotClass } = getBadge(status.name);
            return (
              <div key={status.name} className="mb-3">
                <div className="grid grid-cols-[auto_1fr] gap-2 items-start">
                  <div className={`${badgeClass} whitespace-nowrap`}>
                    <strong>{status.name}</strong>
                  </div>
                  <div className="text-sm text-gray-700 mt-1.5">{status.description}</div>
                </div>
              </div>
            );
          })}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};