import React from "react";
export default function Footer({ isLoggedIn }) {
  const supportLink = isLoggedIn ? "/assessor-support" : "/homeowner-support";

  return (
    <footer className="fs-container pb-8 pt-10 text-base text-left flex justify-between">
    <p>
        Having issues with the platform? Please visit our <a className="underline" href={supportLink}>support page</a>.
      </p>
    <p>
      <a className="underline" href="/privacy-policy">Privacy Policy</a>
    </p>
    </footer>
  );
}
