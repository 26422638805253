import React, {useState} from "react";
import { motion } from "framer-motion"; // Import Framer Motion

import { NavLink } from 'react-router-dom'
import { ERROR_MESSAGE_DURATION } from '../../shared/Constants';
import { AiOutlineSearch } from "react-icons/ai";
import { CiMail } from "react-icons/ci";
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import SupportPageTemplate from "./SupportPageTemplate";

function AssessorSupportPage() {

    const title = "Platform Support";
    const paragraph = "Need help with something? Check out our frequently asked questions below.";

    const faqs = [
        { question: "How will assessment requests be managed in regions with FireSmart Coordinators or UBCM programs?", answer: "Assessment requests will be routed through the platform. Coordinators can approve, assign, or reassign requests based on their region's workflows. Assessments can also be started “on-the-spot” by selecting 'create new assessment' and following the prompts within the platform." },
        { question: "Is the platform compatible with Mac, PC, and other operating systems?", answer: "Yes, since the platform is browser-based, it is compatible with Mac, PC, Linux, and other operating systems whether you use an iPhone, Android or tablet or laptop." },
        { question: "Will data connectivity be required to perform assessments in the field?", answer: "Yes, the platform requires an internet connection. However, coordinators can complete an assessment offline and upload it later when connectivity is restored." },
        { question: "Can printed assessments still be provided to homeowners without internet access?", answer: "Yes, coordinators can print completed assessments and hand deliver them or send via post if clients lack internet access or would prefer a hard copy." },
        { question: "Can assessments be done for residents who verbally request them, especially for elders or non-tech-savvy individuals?", answer: "Yes, coordinators can input assessments on behalf of clients and send them a follow-up email for confirmation. In the event the client is unable to use email, the client will need to consent to the TOS and Privacy policy in person through your mobile device before you begin the assessment." },
        { question: "Can a user level WMS assign an assessment to themselves without the coordinator’s involvement?", answer: "No, coordinators typically manage the assignment process." },
        { question: "Can coordinators input assessment requests on behalf of residents (e.g., via phone)?", answer: "Yes, coordinators can submit requests on behalf of clients and the platform will send an email to them for confirmation." },
        { question: "How many coordinator level WMSs will be allowed per jurisdiction?", answer: "Each jurisdiction will be assessed on a case by case basis, the exact number will depend on the communities’ configurations and needs." },
        { question: "Will WMS personnel be able to see assessments conducted by their colleagues in the same region?", answer: "This depends on the user permission level settings. By default, WMS personnel only see assessments assigned to them. Coordinators can see all assessments in their jurisdiction." },
        { question: "Will WMS or coordinators receive email notifications when new assessment applications are submitted?", answer: "Yes, email notifications for new applications are enabled by default. Notification toggles on the User profile settings are being considered for the FireSmart Network personnel. Once implemented, Coordinators and WMS personnel will be able to toggle these notifications in their profile settings." },
        { question: "Will the platform allow saved or custom comments for recurring use during assessments?", answer: "Currently we are limited to one default comment per question, but yes, the platform will include a feature for saving frequently used comments, making the assessment process more efficient." },
        { question: "Can photos be uploaded or captured directly within the platform during assessments?", answer: "Yes, the platform allows photos to be uploaded or taken directly during the assessment process." },
        { question: "Will all historical assessment data transfer to the new platform?", answer: "Provided the homeowner has agreed to the data transfer from FSC to FSBC, their previously completed assessments will be migrated to the new platform, ensuring continuity." },
        { question: "Can assessments be reassigned to another agency?", answer: "Yes, reassignment is possible through the assessment overview page provided you have the necessary permissions. Otherwise a request must be sent to the WMP support team at <a href='mailto:wmp.support@firesmartbc.ca'>wmp.support@firesmartbc.ca</a>." },
        { question: "Can measurement tools (e.g., iPhone apps) be integrated into the platform?", answer: "Currently, there is no integration for third-party measurement tools. However, this is being evaluated for future updates." },
        { question: "Can clickable links or resources (e.g., IBHS Ember Storm) be added to assessment reports?", answer: "Yes, the platform allows adding links to reports, providing additional resources to clients." },
        { question: "Can coordinators access assessment reports submitted by their WMS team members?", answer: "Yes, coordinators have full access to all assessments within their jurisdiction." },
        { question: "Will there be a notification system for overdue assessments?", answer: "Currently, oversight of timely assessment progression is the responsibility of the local FireSmart Coordinator. Automated notifications may be implemented in the future if we receive enough requests for such a feature." },
        { question: "Can coordinators set assessment priorities?", answer: "Coordinators are able to leave a note indicating a level of priority when assigning assessments." },
        { question: "Can coordinators approve multiple assessments in bulk?", answer: "Bulk approval is not currently available but is under consideration for future updates." },
        { question: "Can coordinators export assessment data for reporting purposes?", answer: "Currently, data from 2025 forward can be exported through the 'Assessments' page. Users are able to filter and export data through .xlsx format." },
        { question: "What happens if multiple coordinators are assigned to the same region?", answer: "The platform allows for collaboration and clear role delineation to prevent duplication of efforts." },
        { question: "What should I do if an email with an assessment link goes to a homeowner’s spam folder?", answer: "Follow up with the client to check their spam folder and open it. Suggest that they update it by right clicking it and clicking 'not spam.'" },
        { question: "What is the process for managing FireSmart data and reporting to CIFFC?", answer: "Data is stored securely in the FireSmart BC platform and shared with CIFFC as part of aggregated reporting. Personal data is not disclosed without explicit consent." },
        { question: "How do FireSmart Rebates work, and who determines the criteria?", answer: "Rebates are managed regionally. Local governments set the criteria and distribute funds provided by the <a href=\"https://www2.gov.bc.ca/gov/content/safety/wildfire-status/prevention/funding-for-wildfire-prevention/crip\">CRI</a> (Community Resiliency Investment) Program." },
    ];

    return (
        <SupportPageTemplate title={title} paragraph={paragraph} questions={faqs} />
    );
}

export default AssessorSupportPage;