import React from "react";
import Form from "react-bootstrap/Form";
export default function HomeInspectionPropertyType({ setPropertyType }) {
  return (
    <Form.Group
      controlId="propertyType"
      className="lg:w-1/2 !flex-none mb-3"
      onChange={(e) => setPropertyType(e.target.value)}
    >
      <Form.Label className="font-bold">Type of Property</Form.Label>
      <Form.Select required className="form-select">
        <option value="">Please select</option>
        <option value="apartment">Apartment/Condo</option>
        <option value="single_family">Detached Home</option>
        <option value="duplex">Duplex</option>
        <option value="mobile_home">Mobile Home</option>
        <option value="multi_family">Multi-Family Home</option>
        <option value="multiplex">Multiplex</option>
        <option value="townhome">Townhome</option>
        <option value="other">Other</option>
      </Form.Select>
      <Form.Control.Feedback type="invalid">
        Please select a property type.
      </Form.Control.Feedback>
    </Form.Group>
  );
}
