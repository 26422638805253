import React, { useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import api from "../utils/api";
import { toast } from "react-toastify";

function EmailInput({ value, onChange, isDuplicate, setIsDuplicate }) {
  const [isLoading, setIsLoading] = useState(false);
  const [validated, setValidated] = useState(false);

  const handleBlur = () => {
    if (value.trim() === "") {
      setIsDuplicate(false);
      setValidated(false);
      return;
    }

    setIsLoading(true);

    api
      .get(`/check-email-duplication/${encodeURIComponent(value)}`)
      .then(
        (response) => {
            console.log(response);
          setIsDuplicate(response.isDuplicate);
          setValidated(true);
          if (response.isDuplicate) {
            toast.error("This email is already in use.");
          }
        },
        (error) => {
          console.error("Error checking email duplication:", error);
          toast.error("Failed to validate email. Try again later.");
        },
      )
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Form.Group controlId="email">
      <Form.Label className="font-bold">Email</Form.Label>
      <div className="position-relative">
        <Form.Control
          type="email"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          onBlur={handleBlur}
          isInvalid={validated && isDuplicate}
          placeholder="Enter email"
          required
        />
        {isLoading && (
          <Spinner
            animation="border"
            role="status"
            size="sm"
            className="position-absolute end-0 me-3 mt-2"
          >
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}
        <Form.Control.Feedback type="invalid">
          This email is already in use. Please use a different email.
        </Form.Control.Feedback>
      </div>
    </Form.Group>
  );
}

export default EmailInput;