import React from "react";
import HomeOwnerQuestion from "./HomeOwnerQuestion";

function HomeOwnerModule({ moduleTitle, moduleQuestions }) {
  return (
    <div>
      <h3 className="test-pdf font-weight-bold">{moduleTitle}</h3>
      {moduleQuestions &&
        moduleQuestions.map((question, index) => (
          <HomeOwnerQuestion
            key={index}
            index={index}
            questionID={question.auditQuestionID}
            questionContent={question.question}
            questionAnswers={question.auditQuestionOptions}
            inspectorComment={question.comment}
            requiresActionValue={question.requiresAction}
            images={question.images}
          />
        ))}
    </div>
  );
}

export default HomeOwnerModule;
