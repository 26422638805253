import React, { useState, useEffect } from 'react';

function PhoneNumberInput({
    controlId,
    label,
    value,
    onChange,
    required,
    invalidFeedback,
    pattern = "^\\d{3}-\\d{3}-\\d{4}$",
    className = '',
    ...props
}) {
    const [phoneNumber, setPhoneNumber] = useState(value || '');

    useEffect(() => {
        if (value && value.length === 10 && /^\d{10}$/.test(value)) {
            // Format phone number if it's 10 digits without dashes
            const formattedValue = value.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
            setPhoneNumber(formattedValue);
        } else {
            setPhoneNumber(value || '');
        }
    }, [value]);

    const handleInputChange = (e) => {
        let inputValue = e.target.value.replace(/\D/g, ''); // Remove all non-numeric characters

        if (inputValue.length > 10) return; // Limit to 10 digits

        let formattedValue = inputValue;
        if (inputValue.length >= 7) {
            formattedValue = `${inputValue.slice(0, 3)}-${inputValue.slice(3, 6)}-${inputValue.slice(6)}`;
        } else if (inputValue.length >= 4) {
            formattedValue = `${inputValue.slice(0, 3)}-${inputValue.slice(3)}`;
        }

        setPhoneNumber(formattedValue);
        if (onChange) onChange(formattedValue); // Pass the formatted value back to the parent component
    };

    return (
        <div className={`form-group ${className}`}>
            {label && <label className="font-bold form-label" htmlFor={controlId}>{label}</label>}
            <input
                type="text"
                id={controlId}
                value={phoneNumber}
                onChange={handleInputChange}
                required={required}
                pattern={pattern}
                className="form-control" 
                {...props}
            />
            {invalidFeedback && (
                <div className="invalid-feedback">
                    {invalidFeedback}
                </div>
            )}
        </div>
    );
}

export default PhoneNumberInput;