import React from "react";
import Question from "./Question";

function Module({
  moduleID,
  moduleTitle,
  moduleQuestions,
  hexCode,
  setCommentCallback,
  setQuestionOptionsCallback,
  setRequiresActionCallback,
  setSupervisorCommentCallback,
  addImageCallback,
  removeImageCallback,
  auditID,
  homeInspectionID,
  className,
  updateStatusesCallback,
  saveInspection,
}) {
  const moduleTitleTrimmed = moduleTitle.split("(")[0].trim();
  const updateStatusCallback = (questionID, newStatus) => {
    updateStatusesCallback(questionID, newStatus);
  };
  return (
    <div id={"mod-" + moduleID} className={className}>
      <h3 name={moduleTitle}>
        {hexCode && (
          <span
            className={`inline-block w-4 h-4 rounded-full mr-2 bg-[#${hexCode}]`}
            style={{ backgroundColor: `#${hexCode}` }}
          ></span>
        )}
        <a name={moduleTitle}>{moduleTitle}</a>
      </h3>
      {moduleQuestions &&
        moduleQuestions
          .sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
          .map((question, index) => (
            <Question
              key={index}
              index={index}
              moduleTitle={moduleTitleTrimmed}
              hexCode={hexCode}
              questionID={question.auditQuestionID}
              questionContent={question.question}
              questionAnswers={question.auditQuestionOptions}
              comment={question.comment}
              homeOwnerComment={question.homeOwnerComment}
              supervisorComment={question.supervisorComment}
              supervisorCommentStatus={question.supervisorCommentStatus}
              homeInspectionCommentID={question.homeInspectionCommentID}
              defaultComment={question.defaultComment}
              requiresActionValue={question.requiresAction}
              images={question.images}
              setCommentCallback={setCommentCallback}
              setSupervisorCommentCallback={setSupervisorCommentCallback}
              setQuestionOptionsCallback={setQuestionOptionsCallback}
              setRequiresActionCallback={setRequiresActionCallback}
              addImageCallback={addImageCallback}
              removeImageCallback={removeImageCallback}
              auditID={auditID}
              homeInspectionID={homeInspectionID}
              updateStatusCallback={updateStatusCallback}
              saveInspection={saveInspection}
            />
          ))}
    </div>
  );
}

export default Module;
