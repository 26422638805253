import React from 'react';
import { useState } from 'react';
import api from '../../shared/utils/api';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import FormGroupInput from '../../shared/components/FormGroupInput';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { ERROR_MESSAGE_DURATION } from '../../shared/Constants';

function HomeInspectionCancel() {
    const [validated, setValidated] = useState(false);
    const { state } = useLocation();

    const [cancellationNote, setCancellationNote] = useState('');

    const submitCancellationNote = () => {
        api.post('/cancel-home-inspection', {
            targetHomeInspectionID: Number(state.homeInspectionId),
            cancellationNote: cancellationNote
        }).then(
            (response) => {
                toast.success('Successfully set cancellation note on home assessment', {
                    autoClose: 2000
                });
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            }
        );
    }

    const navigate = useNavigate();

    const handleCancel = (e) => {
        e.preventDefault();
        navigate(-1);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = e.currentTarget;

        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            setValidated(true);
            return;
        }

        setValidated(true);
        submitCancellationNote();
        navigate(-1);
    }

    return (
        <div>
            <h2>Cancel Home Assessment 
                <FontAwesomeIcon
                    data-tooltip-id="cancellation-form-tooltip"
                    data-tooltip-content="Use this form to record a note on why a home assessment is being cancelled."
                    size='xs'
                    icon={faCircleQuestion}
                />
                <Tooltip id="cancellation-form-tooltip" />
            </h2>
            <div class="row">
                <div class="col">
                    <div class="form-container">
                        <h4>Are you sure you want to cancel this assessment?</h4>
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <Row>
                                <FormGroupInput
                                    controlId="cancellationNote"
                                    label="Cancellation Note"
                                    required={true}
                                    onChange={(e) => setCancellationNote(e.target.value)}
                                    type="text"
                                    invalidFeedback="Please enter a cancellation note."
                                />
                            </Row>
                            <Row>
                                <Form.Group class="form-group">
                                    <Button className="btn btn-standard secondary margin-right-10" onClick={handleCancel}>Go Back</Button>
                                    <Button type="submit" className="btn btn-danger">Cancel Assessment</Button>
                                </Form.Group>
                            </Row>
                        </Form>
                    </div>
                </div>
             </div>
        </div>
    );
}

export default HomeInspectionCancel;
