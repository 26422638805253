import { Image, Loader } from 'lucide-react';
import React, { useEffect, useState } from 'react';

function UploadImages({ imageFiles, setImageFiles, setImagesPreview }) {
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const MAX_FILE_SIZE_MB = 2; // Maximum file size in MB
  const MAX_WIDTH = 1000; // Maximum image width for resizing
  const ALLOWED_FILE_TYPES = ['image/png', 'image/jpeg', 'image/jpg'];

  const downsizeImage = (file) => {
    return new Promise((resolve, reject) => {
      const img = new window.Image(); // Use the native Image object explicitly
  
      const reader = new FileReader();
  
      reader.onload = (e) => {
        img.src = e.target.result;
  
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
  
          let width = img.width;
          let height = img.height;
  
          if (width > MAX_WIDTH) {
            const scaleFactor = MAX_WIDTH / width;
            width = MAX_WIDTH;
            height = height * scaleFactor;
          }
  
          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);
  
          // Compress the image to Blob
          canvas.toBlob(
            (blob) => {
              if (blob.size / 1024 / 1024 <= 2) { // Check size (2MB limit)
                resolve(new File([blob], file.name, { type: file.type }));
              } else {
                reject(new Error('Failed to resize image below 2MB.'));
              }
            },
            file.type,
            0.7 // Compression quality
          );
        };
  
        img.onerror = () => reject(new Error('Image loading failed.'));
      };
  
      reader.onerror = () => reject(new Error('File reading failed.'));
      reader.readAsDataURL(file);
    });
  };

  const handleFileSelection = async (selectedFiles) => {
    const validFiles = Array.from(selectedFiles).filter((file) =>
      ALLOWED_FILE_TYPES.includes(file.type)
    );

    if (validFiles.length === 0) {
      alert('No valid files selected. Please select PNG, JPEG, or JPG files.');
      return;
    }

    setIsLoading(true);

    const resizedFiles = await Promise.all(
      validFiles.map(async (file) => {
        if (file.size / 1024 / 1024 > MAX_FILE_SIZE_MB) {
          try {
            return await downsizeImage(file);
          } catch (error) {
            console.error('Resizing error:', error.message);
            alert(`Could not resize ${file.name}.`);
            return null;
          }
        }
        return file; // If the file is already under the size limit, return it as is.
      })
    );

    const filteredFiles = resizedFiles.filter(Boolean); // Remove any null values from resizing failures

    if (filteredFiles.length > 0) {
      setImageFiles((prev) => [...prev, ...filteredFiles]);
      const imageUrls = filteredFiles.map((file) => URL.createObjectURL(file));
      setImagesPreview((prev) => [...prev, ...imageUrls]);
    }

    setIsLoading(false);
  };

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    if (selectedFiles?.length > 0) {
      handleFileSelection(selectedFiles);
    }
  };

  const handleCameraCapture = async (event) => {
    const capturedFile = event.target.files[0];
    if (capturedFile) {
      handleFileSelection([capturedFile]);
    }
  };

  useEffect(() => {
    return () => {
      imageFiles.forEach((file) => URL.revokeObjectURL(file));
    };
  }, [imageFiles]);

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDraggingOver(false);
    const droppedFiles = e.dataTransfer.files;
    if (droppedFiles?.length > 0) {
      handleFileSelection(droppedFiles);
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDragLeave = () => {
    setIsDraggingOver(false);
  };

  return (
    <div
      className={`mt-2.5 ${isDraggingOver ? 'bg-gray-200' : 'upload_con'}`}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      style={{
        padding: '30px',
        border: '1px dashed #000',
        borderRadius: '8px',
      }}
    >
      {isLoading ? (
        <div className='h-[150px] flex flex-col items-center justify-center gap-3'>
          <Loader className='mt-3 animate-spin' />
          <p>Processing Image...</p>
        </div>
      ) : (
        <div className='h-[150px] flex flex-col items-center justify-center gap-2'>
          <Image />
          <p className='mt-1.5'>Drag & drop an image here</p>
          <div className='-mt-2 flex gap-2'>
            <input
              type='file'
              id='ctl'
              accept='.png, .jpeg, .jpg'
              multiple
              onChange={handleFileChange}
              style={{
                opacity: 0,
                position: 'absolute',
                zIndex: -1,
              }}
            />
            <label htmlFor='ctl'>
              <span className='ml-1 pt-1.5 pb-0.5 px-2.5 rounded-full text-sm antialiased bg-[#efc901] hover:bg-[#f3d646] text-black cursor-pointer'>
                Choose File
              </span>
            </label>
            <input
              type='file'
              id='camera'
              accept='image/*'
              capture='environment'
              onChange={handleCameraCapture}
              style={{
                opacity: 0,
                position: 'absolute',
                zIndex: -1,
              }}
              className='xl:hidden'
            />
            <label htmlFor='camera' className='xl:hidden'>
              <span className='pt-1.5 pb-0.5 px-2.5 rounded-full text-sm antialiased bg-[#efc901] hover:bg-[#f3d646] text-black cursor-pointer'>
                Take Photo
              </span>
            </label>
          </div>
        </div>
      )}
    </div>
  );
}

export default UploadImages;