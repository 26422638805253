import { useReactToPrint } from "react-to-print";
import { AiOutlineDownload } from "react-icons/ai";

export default function DownloadPdfButton({ contentRef }) {

  const reactToPrintFn = useReactToPrint({
    contentRef,
    pageStyle: `
      @page {
        size: auto;
        margin: 0cm;
        padding: 1cm;
      }
      
      @page :first { margin: 0cm; }
      @page :left { margin: 0cm; }
      @page :right { margin: 0cm; }
      .pdf-image-row { display: flex; }

      .row.pdf-image-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }

      .row.pdf-image-container > div {
        display: grid;
        
      }

      img.audit-image-full { flex: 1; }

      .audit-question-container.border-border.border.rounded-md.p-4.mb-4.shadow-sm {
        margin-top: 30px;
        padding: 0px!important;
        border: 0px !important;
        box-shadow: none !important;
      }

      .btn-standard.pdf-button { display: none; }
      
      textarea.form-control.mt-2.mb-2.pdf-comment__textarea {
        display: none!important;
      }

      p.pdf-comment__paragraph {
        display: block!important;
      }

      .pdf-hide {
        display: none!important;
      }

      .pdf-images {
        display: block!important;
        z-index: 100!important;
      }

      h2.pdf-audit-title {
        margin-bottom: 0px;
      }

    `
  });

  return (
    <button 
      onClick={reactToPrintFn} 
      className="flex items-center gap-2.5 btn-standard bg-transparent border border-black hover:bg-[#000!important] hover:text-white pdf-button"
    >
      <AiOutlineDownload size={20} /> <span className="mt-1">Download as PDF</span>
    </button>
  )
}
