import react from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IoMdCloseCircle } from "react-icons/io";


function Image({ key, imageURL, removeImageCallback }) {
    const style = {
        backgroundImage: `url(${imageURL || "https://via.placeholder.com/150"})`
    };

    const removeImage = (e) => {
        removeImageCallback();
    }

    const showImageFullScreen = (e) => {
        if (e.target.tagName !== "DIV") return;
        var fullpage = document.getElementById('fullpage');
        fullpage.style.display = 'block';
        fullpage.style.backgroundImage = 'url(' + imageURL + ')';
    }

    return (
        <div className="col-md-3 relative" key={key}>
            <div className="audit-image-preview audit-image-full" style={style} onClick={showImageFullScreen}>
               
                    <IoMdCloseCircle className="h-7 w-7 absolute -top-2.5 -right-2.5 cursor-pointer hover:text-gray-800 transition-all duration-300 bg-gray-100 hover rounded-full pdf-hide" onClick={removeImage} />
               
            </div>
        </div>
    );
}

export default Image;
