import React from "react";
import { useState, useRef } from "react";
import api from "../shared/utils/api";
import { toast } from "react-toastify";
import FormGroupInput from "../shared/components/FormGroupInput";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { ERROR_MESSAGE_DURATION } from "../shared/Constants";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons


function ChangePassword(props) {
    const [validated, setValidated] = useState(false);
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [showNewPassword, setShowNewPassword] = useState(false); // State for new password visibility


    const toastID = useRef(null);

    const handleCancel = (e) => {
        e.preventDefault();
        props.cancelCallback();
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            setValidated(true);
            return;
        }

        setValidated(true);

        toastID.current = toast("Saving...", { autoClose: false });

        api.post("/change-password", {
            email: props.email,
            oldPassword: oldPassword,
            newPassword: newPassword,
        }).then(
            () => {
                toast.update(toastID.current, {
                    render: "Password Changed!",
                    type: "success",
                    autoClose: 3000,
                });
                props.successCallback();
            },
            (error) => {
                toast.update(toastID.current, {
                    render: "Error Changing Password! " + error.message,
                    type: "error",
                    autoClose: ERROR_MESSAGE_DURATION,
                });
            }
        );
    };

    return (
        <div>
            <div class="form-container">
                <h4>Change Password</h4>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Row>
                        <FormGroupInput
                            label="Email"
                            name="email"
                            type="text"
                            value={props.email}
                            disabled={true}
                        />
                    </Row>
                    {!props.isAdmin && (
                        <Row>
                            <div className="form-group position-relative w-full">
                                <FormGroupInput
                                    label="Old Password"
                                    name="oldPassword"
                                    type={showNewPassword ? "text" : "password"}
                                    value={oldPassword}
                                    invalidFeedback="Password must be at least 8 characters long, contain an uppercase and lowercase letter, a number, and a special character."
                                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_])[A-Za-z\d@$!%*?&_]{8,}$"
                                    onChange={(e) => setOldPassword(e.target.value)}
                                />
                                <div
                                    onClick={() => setShowNewPassword(!showNewPassword)}
                                    style={{
                                        position: 'absolute',
                                        right: '25px',
                                        bottom: '12px',
                                        background: 'none',
                                        border: 'none',
                                        cursor: 'pointer'
                                    }}
                                >
                                    {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                                </div>
                            </div>
                        </Row>
                    )}
                    <Row>
                        <div className="form-group position-relative w-full">
                            <FormGroupInput
                                label="New Password"
                                name="newPassword"
                                type={showNewPassword ? "text" : "password"}
                                value={newPassword}
                                invalidFeedback="Password must be at least 8 characters long, contain an uppercase and lowercase letter, a number, and a special character."
                                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_])[A-Za-z\d@$!%*?&_]{8,}$"
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                            <div
                                onClick={() => setShowNewPassword(!showNewPassword)}
                                style={{
                                    position: 'absolute',
                                    right: '25px',
                                    bottom: '12px',
                                    background: 'none',
                                    border: 'none',
                                    cursor: 'pointer'
                                }}
                            >
                                {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                            </div>
                        </div>
                    </Row>
                    <Row>
                        <Form.Group class="form-group">
                            <Button
                                className="btn-standard margin-right-10"
                                type="submit"
                            >
                                Save
                            </Button>
                            <Button
                                className="btn-standard danger"
                                onClick={handleCancel}
                            >
                                Cancel
                            </Button>
                        </Form.Group>
                    </Row>
                </Form>
            </div>
        </div>
    );
}

export default ChangePassword;
