import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../shared/utils/api";
import { toast } from "react-toastify";
import { ERROR_MESSAGE_DURATION } from "../../shared/Constants";
import FormGroupInput from "../../shared/components/FormGroupInput";
import { Button, Form, Row } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import Users from "../Users";

function Territory() {
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;

  const [name, setName] = useState("");
  const [territoryId, setTerritoryId] = useState();

  // Fetch territory if editing
  useEffect(() => {
    if (id) {
      api.get(`/territories/${id}`).then(
        (response) => {
          setName(response.name || "");
          setTerritoryId(response.id);
        },
        (error) => {
          toast.error("Failed to load territory: " + error.message, {
            autoClose: ERROR_MESSAGE_DURATION,
          });
        }
      );
    }
  }, [id]);

  const handleDelete = () => {
      api.delete(`/territories/${id}`).then(
        () => {
          toast.success("Assessment has been deleted!", {
            autoClose: 2000,
          });
          navigate("/agencies");
        },
        (error) => {
          toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
        },
      );
    };

  // Handle name change
  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handlePromptDelete = (e) => {
    e.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="confirm-dialog">
            <h3>Delete Agency</h3>
            <p>Are you sure you would like to delete this Agency?</p>
            <button
              className="btn btn-danger float-right"
              onClick={() => {
                handleDelete();
                onClose();
              }}
            >
              Delete
            </button>
            <button
              className="btn btn-standard secondary margin-right-10 float-right"
              onClick={onClose}
            >
              No
            </button>
          </div>
        );
      },
    });
  };

  // Save or create territory
  const handleSave = (e) => {
    e.preventDefault();

    if (id) {
      // Update existing territory
      api.put(`/territories/${id}`, { id: territoryId, name: name }).then(
        () => {
          toast.success("Territory updated successfully!");
          navigate("/agencies"); // Navigate back to the list
        },
        (error) => {
          toast.error("Failed to update territory: " + error.message, {
            autoClose: ERROR_MESSAGE_DURATION,
          });
        }
      );
    } else {
      // Create new territory
      api.post("/territories", { name: name }).then(
        () => {
          toast.success("Territory created successfully!");
          navigate("/agencies"); // Navigate back to the list
        },
        (error) => {
          toast.error("Failed to create territory: " + error.message, {
            autoClose: ERROR_MESSAGE_DURATION,
          });
        }
      );
    }
  };

  return (
    <div class="form-container">
      <h4>
        {id ? "Edit" : "Create"}&nbsp;Agency
      </h4>
      <form onSubmit={handleSave}>
        <Row>
          <FormGroupInput
            label="Agency Name"
            name="name"
            type="text"
            value={name}
            placeholder="Enter Agency Name"
            onChange={handleNameChange}
            required={true} />
        </Row>
        <Row>
          <div className="d-flex align-items-center justify-content-between">
            <Form.Group className="form-group d-flex">

              <Button
                type="submit"
                className='btn-standard margin-right-10'
              >
                {id ? "Save Changes" : "Create Agency"}
              </Button>

              <Button
                onClick={() => navigate("/agencies")}
                className="btn-standard danger"
              >
                Cancel
              </Button>
            </Form.Group>
            {/* Commenting out Delete endpoint btn for now */}
            {/* {id && (
              <Button
                onClick={handlePromptDelete} // Ensure handleDelete is defined
                className="btn-danger"
              >
                Delete
              </Button>
            )} */}
          </div>
        </Row>

      </form>

      <Users apiUrl={'/users-for-territory/' + id} />
    </div>
  );
}

export default Territory;