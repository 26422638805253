import React from "react";
import CheckBox from "../../../shared/components/CheckBox";

function MultiSelectOptions({ questionID, header, options, selectedOptions, updateCallback, disabled, className, index }) {
    const groupName = `${questionID}-${header}`;

    const checked = (optionID) => selectedOptions.includes(optionID);

    return (
        <div class={`audit-options-group ${className} mb-auto`}>
            {header && <label class="font-weight-bold">{header}</label>}
            {options.sort((a, b) => (a.sortOrder > b.sortOrder) ? 1 : -1).map((answer) => (
                <div key={index}>
                    <CheckBox
                        name={groupName}
                        label={answer.option}
                        className={
                            options.length === 1
                                ? 'font-weight-bold'
                                : ''
                        }
                        disabled={disabled}
                        checked={checked(answer.auditQuestionOptionID)}
                        onChange={() => updateCallback && updateCallback(answer.auditQuestionOptionID)}
                        parentIndex={index}
                        
                    />
                </div>
            ))}
        </div>
    );
}

export default MultiSelectOptions;
