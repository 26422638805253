import React from "react";
import { useState, useEffect } from "react";
import api from "../../shared/utils/api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormGroupInput from "../../shared/components/FormGroupInput";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import FormGroupProvinceDropdownInput from "../../shared/components/FormGroupProvinceDropdownInput";
import FormGroupAuditDropDownInput from "../../shared/components/FormGroupAuditDropDownInput";
import HomeInspectionPropertyType from "./HomeInspectionPropertyType";
import PostalCodeInput from "../../shared/components/PostalCodeInput";
import { ERROR_MESSAGE_DURATION } from "../../shared/Constants";
import { isUserAdmin } from "../../shared/utils/authToken";
import HomeInspectionWaiver from "./HomeInspectionWaiver";
import PhoneNumberInput from "../../shared/components/PhoneNumberInput";

function HomeInspectionAdd() {
  const [validated, setValidated] = useState(false);
  const [auditID, setAuditID] = useState(1);
  const [homeOwnerEmail, setHomeOwnerEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [homeOwnerName, setHomeOwnerName] = useState("");
  const [homeOwnerPhoneNumber, setHomeOwnerPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("BC");
  const [postalCode, setPostalCode] = useState("");
  const [territories, setTerritories] = useState([]);
  const [selectedTerritoryId, setSelectedTerritoryId] = useState();
  const [propertyType, setPropertyType] = useState("");
  const handleTerritoryChange = (e) => {
    setSelectedTerritoryId(e.target.value);
  };

  useEffect(() => {
    const requestUrl = isUserAdmin() ? "/territories" : "/territories-for-user";
    api.get(requestUrl).then(
      (response) => {
        setTerritories(response);
      },
      (error) => {
        toast.error("Failed to load territories. Error: " + error, {
          autoClose: ERROR_MESSAGE_DURATION,
        });
      },
    );
  }, []);

  const newInspection = () => {
    // TODO: input sanitization
    api
      .post("/home-inspections", {
        //TODO: Audit ID will come from what type of Audit the user is requesting
        // since we don't have that we are going to hardcode it to 1
        auditID: Number(auditID),
        homeInspectionStatus: "Requested",
        homeOwnerEmailAddress: homeOwnerEmail.trim().toLowerCase(),
        // TODO: phone number sanitization (10 digits, no spaces, no dashes, prepend with +1)
        homeOwnerPhoneNumber:
          homeOwnerPhoneNumber.startsWith("+1")
            ? homeOwnerPhoneNumber?.replace(/[\(\)-]/g, "")
            : "+1" + homeOwnerPhoneNumber?.replace(/[\(\)-]/g, ""),
        address: address,
        city: city,
        province: province,
        // TODO: postal code sanitization (7 characters, 1 space in middle)
        postalCode: postalCode,
        territoryId: +selectedTerritoryId,
        propertyType: propertyType,
        homeOwnerName: homeOwnerName,
      })
      .then(
        () => {
          toast.success("New assessment created!", {
            autoClose: 2000,
          });
          navigate("/home-inspections");
        },
        (error) => {
          toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
        },
      );
  };

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
      return;
    }

    if (homeOwnerEmail !== confirmEmail) {
      toast.error("Email addresses must match");
      e.stopPropagation();
      setValidated(true);
      return;
    }

    setValidated(true);
    newInspection();
    //navigate("/home-inspections");
  };

  return (
    <div>
      <h2> New Assessment </h2>
      <p>Use this form to create a new assessment.</p>
      <div class="row">
        <div>
          <div className="col">
            <div className="form-container">
              <h4>New Assessment Form</h4>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row>
                  <FormGroupAuditDropDownInput
                    value={auditID}
                    onChange={(e) => setAuditID(e.target.value)}
                    required={true}
                  />
                </Row>
                <Row className="flex flex-wrap">
                  <Form.Group
                    as={Col}
                    controlId="territory"
                    className="form-group"
                  >
                    <Form.Label className="font-bold">
                      Agency
                    </Form.Label>
                    <Form.Select
                      required
                      className="form-select"
                      onChange={handleTerritoryChange}
                      value={selectedTerritoryId}
                    >
                      <option value="">Please select</option>
                      {territories.map((territory) => (
                        <option key={territory.id} value={territory.id}>
                          {territory.name}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Must select one of the agencies listed.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row>
                  <FormGroupInput
                    controlId="homeOwnerName"
                    label="Home Owner's Name"
                    required={true}
                    type="text"
                    invalidFeedback="Must provide a valid name."
                    onChange={(e) => setHomeOwnerName(e.target.value)}
                  />
                </Row>
                <Row>
                  <FormGroupInput
                    controlId="homeOwnerEmail"
                    label="Home Owner's Email Address"
                    required={true}
                    type="email"
                    invalidFeedback="Must provide a valid email."
                    onChange={(e) => setHomeOwnerEmail(e.target.value)}
                  />
                </Row>
                <Row>
                  <FormGroupInput
                    controlId="confirmHomeOwnerEmail"
                    label="Confirm Home Owner's Email Address"
                    required
                    type="email"
                    value={confirmEmail}
                    invalidFeedback="Email addresses must match."
                    onChange={(e) => setConfirmEmail(e.target.value)}
                    onPaste={(e) => {
                      e.preventDefault();
                      toast.warn("Please type the email address again");
                    }}
                    isInvalid={confirmEmail !== "" && confirmEmail !== homeOwnerEmail}
                    className="mb-3"
                  />
                </Row>
                <Row>
                  <PhoneNumberInput
                    controlId="homeOwnerPhoneNumber"
                    label="Home Owner's Phone Number"
                    onChange={(e) => setHomeOwnerPhoneNumber(e)}
                    required={false}
                    invalidFeedback="Must provide a phone number in the format 123-456-7890."
                    pattern="^\d{3}-\d{3}-\d{4}$"
                  />
                </Row>
                <Row>
                  <FormGroupInput
                    controlId="address"
                    label="Address"
                    required={true}
                    type="text"
                    invalidFeedback="Must provide an address with allowed special characters .:-_', and allowed spaces."
                    onChange={(e) => setAddress(e.target.value)}
                    pattern="^[a-zA-Z0-9 .:,-_']+$"
                  />
                </Row>
                <Row>
                  <FormGroupInput
                    controlId="city"
                    label="City"
                    required={true}
                    type="text"
                    invalidFeedback="Must provide a city with allowed special characters .:-_', and allowed spaces."
                    onChange={(e) => setCity(e.target.value)}
                    pattern="^[a-zA-Z0-9 .:,-_']+$"
                  />
                </Row>
                <Row>
                  <FormGroupProvinceDropdownInput
                    value={province}
                    onChange={(e) => setProvince(e.target.value)}
                    required={true}
                    placeHolderRequired={true}
                  />
                </Row>
                <Row>
                  <PostalCodeInput
                    controlId="postalCode"
                    label="Postal Code"
                    required={true}
                    type="text"
                    invalidFeedback="Must provide a postal code matching the A1A 1A1 pattern."
                    value={postalCode}
                    onChange={(e) => setPostalCode(e)}
                    pattern="^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$"
                  />
                </Row>
                <Row>
                  <HomeInspectionPropertyType setPropertyType={setPropertyType} />
                </Row>
                <Row>
                  <Form.Group
                    className="flex items-start"
                    controlId="termsAndConditions"
                  >
                    <HomeInspectionWaiver text="The homeowner has agreed to the" />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group
                    className="flex items-start"
                    controlId="termsAndConditions"
                  >
                    <Form.Check
                      required
                      type="checkbox"
                      feedback="You must confirm that the homeowner has given permission."
                      feedbackType="invalid"
                      className="-mt-1 mr-2 "
                    />
                    <span className="flex-1">
                      The homeowner has given permission to have this assessment
                      conducted.
                    </span>
                  </Form.Group>
                </Row>
                <Row className="pt-3 mb-2">
                  <Form.Group class="form-group">
                    <Button className="btn-standard margin-right-10" type="submit">
                      Create Assessment
                    </Button>
                  </Form.Group>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeInspectionAdd;
