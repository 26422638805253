import * as React from "react";
import { useState, useEffect } from "react";
import api from "../../shared/utils/api";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import FormGroupInput from "../../shared/components/FormGroupInput";
import FormGroupProvinceDropdownInput from "../../shared/components/FormGroupProvinceDropdownInput";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import { ERROR_MESSAGE_DURATION } from "../../shared/Constants";
import PostalCodeInput from "../../shared/components/PostalCodeInput";
import { getLoggedInUser, isUserAdmin, isUserCoordinator } from "../../shared/utils/authToken";
import { MdOutlineArrowOutward } from "react-icons/md";
import { format, parseISO } from "date-fns";
import PhoneNumberInput from "../../shared/components/PhoneNumberInput";

import { IoDocumentTextOutline } from "react-icons/io5";
import { MdOutlineInfo } from "react-icons/md";
import { LiaComments } from "react-icons/lia";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function HomeInspection() {
  const [homeOwnerName, setHomeOwnerName] = useState("");
  const [propertyType, setPropertyType] = useState("");
  const [dateTime, setDateTime] = useState(null);
  const [validated, setValidated] = useState(false);
  const [homeInspectionStatus, setHomeInspectionStatus] = useState("");
  const [auditID, setAuditID] = useState(0);
  const [homeInspectionAccessCode, setHomeInspectionAccessCode] = useState("");
  const [homeOwnerEmailAddress, setHomeOwnerEmailAddress] = useState("");
  const [homeOwnerPhoneNumber, setHomeOwnerPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [parcelID, setParcelID] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [assignedInspectorID, setAssignedInspectorID] = useState("");
  const [assignedInspectorFirstName, setAssignedInspectorFirstName] =
    useState("");
  const [assignedInspectorLastName, setAssignedInspectorLastName] =
    useState("");
  const [assignedInspectorNote, setAssignedInspectorNote] = useState("");
  const [changesRequestedNote, setChangesRequestedNote] = useState("");
  const [changesSubmittedNote, setChangesSubmittedNote] = useState("");
  const [cancellationNote, setCancellationNote] = useState("");
  const [, setAuditPresignedURL] = useState("");
  const [, setAuditTitle] = useState("");
  const [territories, setTerritories] = useState([]);
  const [selectedTerritoryId, setSelectedTerritoryId] = useState("");
  const [creationDate, setCreationDate] = useState("");
  const [certificationDate, setCertificationDate] = useState("");
  const [assessmentStartDate, setAssessmentStartDate] = useState("");
  const [deliveryDate, setDeliveryDate] = useState("");
  const [approvedDate, setApprovedDate] = useState("");

  const [approvedByName, setApprovedByName] = useState("");
  const [assignedByName, setAssignedByName] = useState("");
  const [startedByName, setStartedByName] = useState("");
  const [cancelledByName, setCancelledByName] = useState("");
  const [completedByName, setCompletedByName] = useState("");
  const [certifiedByName, setCertifiedByName] = useState("");

  const handleTerritoryChange = (e) => {
    setSelectedTerritoryId(e.target.value);
  };

  const navigate = useNavigate();
  const params = useParams();

  const { id } = params;

  useEffect(() => {
    const requestUrl = isUserAdmin() ? "/territories" : "/territories-for-user";
    api.get(requestUrl).then(
      (response) => {
        setTerritories(response);
      },
      (error) => {
        toast.error("Failed to load territories. Error: " + error, {
          autoClose: ERROR_MESSAGE_DURATION,
        });
      },
    );
  }, []);

  useEffect(() => {
    const fetchHomeInspection = () => {
      api.get("/home-inspection/" + id).then(
        (response) => {
          setApprovedByName(response.approvedByName);
          setCertifiedByName(response.certifiedByName);
          setHomeOwnerName(response.homeOwnerName);
          setPropertyType(response.propertyType);
          setHomeInspectionStatus(response.homeInspectionStatus);
          setAuditID(response.auditID);
          setHomeInspectionAccessCode(response.homeInspectionAccessCode);
          setHomeOwnerEmailAddress(response.homeOwnerEmailAddress);
          setHomeOwnerPhoneNumber(response.homeOwnerPhoneNumber?.replace("+1", ""));
          setAddress(response.address);
          setCity(response.city);
          setProvince(response.province);
          setPostalCode(response.postalCode);
          setParcelID(response.parcelID);
          setLatitude(response.latitude);
          setLongitude(response.longitude);
          setAssignedInspectorID(response.assignedInspectorID);
          setAssignedInspectorFirstName(response.assignedInspectorFirstName);
          setAssignedInspectorLastName(response.assignedInspectorLastName);
          setAssignedInspectorNote(response.assignedInspectorNote);
          setChangesRequestedNote(response.changesRequestedNote);
          setChangesSubmittedNote(response.changesSubmittedNote);
          setCancellationNote(response.cancellationNote);
          setSelectedTerritoryId(response.territoryId);
          setDateTime(
            response.scheduledDate
              ? new Date(response.scheduledDate)
              : response.scheduledDate,
          );
          setCreationDate(response.creationDate);
          setAssessmentStartDate(response.assessmentStartDate);
          setDeliveryDate(response.completedDate);
          setApprovedDate(response.approvedDate);
          setCertificationDate(response.certificationDate);
          setHomeOwnerName(response.homeOwnerName);
        },
        (error) => {
          toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
        },
      );
    };

    fetchHomeInspection();
  }, [id]);

  useEffect(() => {
    if (auditID === 0) {
      return;
    }
    const fetchAudit = () => {
      api.get("/audits/" + auditID).then(
        (response) => {
          setAuditPresignedURL(response.presignedURL);
          setAuditTitle(response.auditTitle);
        },
        (error) => {
          toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
        },
      );
    };

    fetchAudit();
  }, [auditID]);

  const updateHomeInspection = () => {
    api
      .post("/home-inspections", {
        homeOwnerName: homeOwnerName,
        propertyType: propertyType,
        homeInspectionID: Number(id),
        auditID: parseInt(auditID),
        homeInspectionStatus: homeInspectionStatus,
        homeOwnerEmailAddress: homeOwnerEmailAddress,
        homeOwnerPhoneNumber:
          homeOwnerPhoneNumber?.startsWith("+1")
            ? homeOwnerPhoneNumber?.replace(/[\(\)-]/g, "")
            : "+1" + homeOwnerPhoneNumber?.replace(/[\(\)-]/g, ""),
        address: address,
        city: city,
        province: province,
        postalCode: postalCode,
        parcelID: parcelID,
        latitude: latitude ? parseFloat(latitude) : null,
        longitude: longitude ? parseFloat(longitude) : null,
        assignedInspectorID: assignedInspectorID ? assignedInspectorID : null,
        assignedInspectorNote: assignedInspectorNote
          ? assignedInspectorNote
          : null,
        changesRequestedNote: changesRequestedNote
          ? changesRequestedNote
          : null,
        changesSubmittedNote: changesSubmittedNote
          ? changesSubmittedNote
          : null,
        cancellationNote: cancellationNote ? cancellationNote : null,
        territoryId: +selectedTerritoryId,
        scheduledDate: dateTime ? new Date(dateTime).toISOString() : null,
      })
      .then(
        (response) => {
          setAuditID(response.auditID);
          setHomeInspectionStatus(response.homeInspectionStatus);
          setHomeInspectionAccessCode(response.homeInspectionAccessCode);
          setHomeOwnerEmailAddress(response.homeOwnerEmailAddress);
          setHomeOwnerPhoneNumber(response.homeOwnerPhoneNumber?.replace("+1", ""));
          setAddress(response.address);
          setCity(response.city);
          setProvince(response.province);
          setPostalCode(response.postalCode);

          // handle nullable fields so form gets updated without needing a refresh
          response.parcelID ? setParcelID(response.parcelID) : setParcelID("");
          response.latitude ? setLatitude(response.latitude) : setLatitude("");
          response.longitude
            ? setLongitude(response.longitude)
            : setLongitude("");
          response.assignedInspectorID
            ? setAssignedInspectorID(response.assignedInspectorID)
            : setAssignedInspectorID("");
          response.assignedInspectorNote
            ? setAssignedInspectorNote(response.assignedInspectorNote)
            : setAssignedInspectorNote("");
          response.changesRequestedNote
            ? setChangesRequestedNote(response.changesRequestedNote)
            : setChangesRequestedNote("");
          response.changesSubmittedNote
            ? setChangesSubmittedNote(response.changesSubmittedNote)
            : setChangesSubmittedNote("");
          response.cancellationNote
            ? setCancellationNote(response.cancellationNote)
            : setCancellationNote("");
          toast.success("Successfully saved changes to assessment", {
            autoClose: 2000,
          });
        },
        (error) => {
          toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
        },
      );
  };

  const submitHomeInspectionStart = () => {
    api
      .post("/start-home-inspection", {
        targetHomeInspectionID: Number(id),
      })
      .then(
        () => {
          setHomeInspectionStatus("In Progress");
          toast.success("Successfully started assessment", {
            autoClose: 2000,
          });
        },
        (error) => {
          toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
        },
      );
  };

  const submitHomeInspectionComplete = () => {
    api
      .post("/submit-home-inspection", {
        targetHomeInspectionID: Number(id),
      })
      .then(
        (response) => {
          if (response != null) {
            setHomeInspectionStatus(response.homeInspectionStatus);
          }
          toast.success("Assessment has been approved!", {
            autoClose: 2000,
          });
        },
        (error) => {
          toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
        },
      );
  };

  const handlePromptDelete = (e) => {
    e.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="confirm-dialog">
            <h3>Delete Assessment</h3>
            <p>Are you sure you would like to delete this assessment?</p>
            <button
              className="btn btn-danger float-right"
              onClick={() => {
                handleDelete();
                onClose();
              }}
            >
              Delete
            </button>
            <button
              className="btn btn-standard secondary margin-right-10 float-right"
              onClick={onClose}
            >
              No
            </button>
          </div>
        );
      },
    });
  };

  const handleDelete = () => {
    api.delete("/home-inspections/" + Number(id)).then(
      () => {
        toast.success("Assessment has been deleted!", {
          autoClose: 2000,
        });
        navigate("/home-inspections");
      },
      (error) => {
        toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
      },
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
      return; // Return if validation failed
    }

    setValidated(true);

    updateHomeInspection();
  };

  const handleAssign = (e) => {
    e.preventDefault();
    navigate("/home-inspection/" + id + "/assign", {
      state: {
        homeInspectionId: id,
        assignedInspectorNote: assignedInspectorNote,
      },
    });
  };

  const handleCertify = () => {
    api.post("/certify-home-inspection/" + id).then(
      () => {
        setHomeInspectionStatus("Certified");
        toast.success("Assessment has been certified!", {
          autoClose: 2000,
        });
      },
      (error) => {
        toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
      },
    );
  };

  const handlePromptCertify = (e) => {
    e.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="confirm-dialog">
            <h3>Confirm Certification</h3>
            <p>Are you sure you want to certify this assessment?</p>
            <button
              className="btn btn-success mr-2"
              onClick={() => {
                handleCertify();
                onClose();
              }}
            >
              Yes
            </button>
            <button className="btn btn-secondary" onClick={onClose}>
              No
            </button>
          </div>
        );
      },
    });
  };

  const handleStartInspection = (e) => {
    e.preventDefault();
    submitHomeInspectionStart();
  };

  const handleCompletePrompt = (e) => {
    e.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="confirm-dialog">
            <h3>Confirm Assessment Approval</h3>
            <p>Are you sure you want to approve this assessment?</p>
            <button
              className="btn btn-success mr-2"
              onClick={() => {
                submitHomeInspectionComplete();
                onClose();
              }}
            >
              Yes
            </button>
            <button className="btn btn-secondary" onClick={onClose}>
              No
            </button>
          </div>
        );
      },
    });
  };

  const handleCancelInspection = (e) => {
    e.preventDefault();
    navigate("/home-inspection/" + id + "/cancel", {
      state: { homeInspectionId: id, cancellationNote: cancellationNote },
    });
  };

  const handleCancel = (e) => {
    e.preventDefault();
    navigate("/home-inspections");
  };

  const handleNavigateToAudit = (e) => {
    e.preventDefault();
    navigate("/home-inspection/" + id + "/inspection", { state: { auditID } });
  };
  const user = getLoggedInUser();

  const mainContent = () => {
    return (
      <div>
        <div className="row">
          <div className="col-md-12 md:flex md:justify-between mb-3">
            <h1 className="h2">
              Assessment Info for {address}, {city}
            </h1>
            {homeInspectionStatus !== "Requested" &&
              homeInspectionStatus !== "Request Verified" &&
              homeInspectionStatus !== "Assigned" &&
              homeInspectionStatus !== "Cancelled" && (
                <button
                  className="btn btn-standard flex-order-1 md:flex-order-0"
                  onClick={handleNavigateToAudit}
                >
                  View Assessment{" "}
                  <MdOutlineArrowOutward className="ml-2 h-4 w-4 -mt-0.5 inline-block" />
                </button>
              )}
          </div>
          <div className="col-md-8 mb-3 md:mb-0">
            <div className="form-container !py-2.5">
              <div className="flex justify-between items-center mb-3">
                <h3 className="flex-order-0 md:flex-order-1 pt-3">
                  <IoDocumentTextOutline className="mr-2 h-6 w-6 -mt-1 inline-block" />
                  Overview
                </h3>
              </div>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row>
                  <Col
                    md={
                      homeInspectionStatus === "Approval Required" &&
                        (isUserAdmin() || isUserCoordinator())
                        ? 8
                        : 12
                    }
                  >
                    <FormGroupInput
                      label="Assessment Status"
                      name="homeInspectionStatus"
                      type="text"
                      value={homeInspectionStatus}
                      onChange={(e) => setHomeInspectionStatus(e.target.value)}
                      disabled
                    />
                  </Col>
                  {homeInspectionStatus === "Approval Required" &&
                    (isUserAdmin() || isUserCoordinator()) && (
                      <Col md={4} className="mt-4">
                        <Button
                          variant="primary"
                          className="mt-2 btn-success"
                          onClick={handleCompletePrompt}
                        >
                          Approve
                        </Button>
                      </Col>
                    )}
                </Row>

                {(homeInspectionStatus !== "Certified") && (
                  <Row>
                    <Form.Group className="form-group">
                      {(homeInspectionStatus === "Requested" ||
                        homeInspectionStatus === "Request Verified" ||
                        homeInspectionStatus === "Assigned") && (
                          <Button
                            className="btn-standard  margin-right-10"
                            type="button"
                            onClick={handleStartInspection}
                          >
                            Start
                          </Button>
                        )}
                      {(isUserAdmin() || isUserCoordinator()) && (
                        <Button
                          className="btn-standard btn-outline margin-right-10 !hover:bg-black !hover:text-primary"
                          type="button"
                          hidden={homeInspectionStatus === "Completed"}
                          onClick={handleAssign}
                        >
                          Assign
                        </Button>
                      )}
                      {isUserAdmin() && (
                        <Button
                          className="btn-standard btn-outline margin-right-10 !hover:bg-black !hover:text-primary"
                          type="button"
                          onClick={handlePromptCertify}
                        >
                          Certify
                        </Button>
                      )}

                      <Button
                        className="btn-standard btn-cancel margin-right-10"
                        type="button"
                        hidden={homeInspectionStatus === "Completed"}
                        onClick={handleCancelInspection}
                      >
                        Cancel Assessment
                      </Button>
                      {(isUserAdmin() || isUserCoordinator()) && (
                        <button
                          className="btn link float-right font-bold"
                          onClick={handlePromptDelete}
                        >
                          Delete Assessment
                        </button>
                      )}
                    </Form.Group>
                  </Row>
                )}

                <Row className="flex flex-wrap">
                  <Form.Group
                    as={Col}
                    controlId="territory"
                    className="form-group lg:w-1/2 !flex-none mb-3"
                  >
                    <Form.Label className="font-bold">Agency</Form.Label>
                    <Form.Select
                      className="form-select"
                      onChange={handleTerritoryChange}
                      value={selectedTerritoryId}
                    >
                      {territories.map((territory) => (
                        <option key={territory.id} value={territory.id}>
                          {territory.name}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Must select one of the territories listed.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <FormGroupInput
                    label="Home Owner Email Address"
                    name="homeOwnerEmailAddress"
                    type="email"
                    value={homeOwnerEmailAddress}
                    required={true}
                    onChange={(e) => setHomeOwnerEmailAddress(e.target.value)}
                    invalidFeedback="Please enter a valid email address."
                    className="lg:w-1/2 !flex-none mb-3"
                  />
                  <FormGroupInput
                    controlId="homeOwnerName"
                    label="Home Owner's Name"
                    required={true}
                    type="text"
                    value={homeOwnerName}
                    invalidFeedback="Must provide a valid name."
                    onChange={(e) => setHomeOwnerName(e.target.value)}
                  />

                  <PhoneNumberInput
                    label="Home Owner Phone Number (Optional)"
                    name="homeOwnerPhoneNumber"
                    controlId="homeOwnerPhoneNumber"
                    value={homeOwnerPhoneNumber}
                    onChange={(e) => setHomeOwnerPhoneNumber(e)}
                    required={false}
                    invalidFeedback="Must provide a phone number in the format 123-456-7890."
                    pattern="^\d{3}-\d{3}-\d{4}$"
                    className="lg:w-1/2 !flex-none mb-3"
                  />

                  <FormGroupInput
                    label="Address"
                    name="address"
                    type="text"
                    value={address}
                    required={true}
                    onChange={(e) => setAddress(e.target.value)}
                    invalidFeedback="Must provide an address with allowed special characters .;:, and allowed spaces."
                    pattern="^[a-zA-Z0-9 .:,-_']+$"
                    className="lg:w-1/2 !flex-none mb-3"
                  />
                  <FormGroupInput
                    controlId="city"
                    label="City"
                    type="text"
                    value={city}
                    required={true}
                    invalidFeedback="Must provide a city with allowed special characters .:-_', and allowed spaces."
                    onChange={(e) => setCity(e.target.value)}
                    pattern="^[a-zA-Z0-9 .:,-_']+$"
                    className="lg:w-1/2 !flex-none mb-3"
                  />
                  <FormGroupProvinceDropdownInput
                    value={province}
                    required={true}
                    onChange={(e) => setProvince(e.target.value)}
                    placeHolderRequired={false}
                    className="lg:w-1/2 !flex-none mb-3"
                  />
                  <div className="lg:w-1/2 !flex-none mb-3">
                    <PostalCodeInput
                      controlId="postalCode"
                      label="Postal Code"
                      required={true}
                      type="text"
                      invalidFeedback="Must provide a postal code matching the A1A 1A1 pattern."
                      value={postalCode}
                      onChange={(e) => setPostalCode(e)}
                      pattern="^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$"
                    />
                  </div>
                  <div className="lg:w-1/2 !flex-none mb-3">
                    <label
                      className="font-bold block mb-1"
                      htmlFor="assessmentDateTime"
                    >
                      Assessment Scheduled Date and Time
                    </label>
                    <DatePicker
                      selected={dateTime}
                      onChange={(date) => setDateTime(date)}
                      showTimeSelect
                      dateFormat="Pp"
                      placeholderText="Select date and time"
                      className="w-full p-2 border rounded cursor-pointer"
                    />
                    {/* Hidden input to store the formatted value */}
                    <input
                      type="hidden"
                      name="assessmentDateTime"
                      value={
                        dateTime
                          ? format(dateTime, "yyyy-MM-dd'T'HH:mm:ss")
                          : ""
                      }
                    />
                  </div>
                  <FormGroupInput
                    label="Latitude"
                    name="latitude"
                    type="text"
                    value={latitude}
                    onChange={(e) => setLatitude(e.target.value)}
                    invalidFeedback="Latitude must be a number between -90.000000 and 90.000000 or be unset."
                    pattern="^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$"
                    className="lg:w-1/2 !flex-none mb-3"
                  />
                  <FormGroupInput
                    label="Longitude"
                    name="longitude"
                    type="text"
                    value={longitude}
                    onChange={(e) => setLongitude(e.target.value)}
                    invalidFeedback="Longitude must be a number between -100.000000 and 100.000000 or be unset."
                    pattern="(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))"
                    className="lg:w-1/2 !flex-none mb-3"
                  />
                  <FormGroupInput
                    label="Parcel ID"
                    name="parcelID"
                    type="text"
                    value={parcelID}
                    onChange={(e) => setParcelID(e.target.value)}
                    pattern="^\d{3}-\d{3}-\d{3}$"
                    invalidFeedback="Parcel ID must be in the format 123-456-789 or be unset."
                    className="lg:w-1/2 !flex-none mb-3"
                  />

                  <Form.Group
                    className="lg:w-1/2 !flex-none mb-3"
                    onChange={(e) => setPropertyType(e.target.value)}
                  >
                    <Form.Label className="font-bold">Type of Property</Form.Label>
                    <Form.Select required className="form-select"
                      value={propertyType}>
                      <option value="">Please select</option>
                      <option value="apartment">Apartment/Condo</option>
                      <option value="single_family">Detached Home</option>
                      <option value="duplex">Duplex</option>
                      <option value="mobile_home">Mobile Home</option>
                      <option value="multi_family">Multi-Family Home</option>
                      <option value="multiplex">Multiplex</option>
                      <option value="townhome">Townhome</option>
                      <option value="other">Other</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please select a property type.
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="form-group">
                    <Button
                      className="btn-standard margin-right-10"
                      type="submit"
                    >
                      Save Edits
                    </Button>
                    <Button
                      className="btn-standard btn-cancel"
                      type="button"
                      onClick={handleCancel}
                    >
                      Cancel Edits
                    </Button>
                  </Form.Group>
                </Row>
              </Form>
            </div>
          </div>

          <div className="col-md-4">
            <div className="form-container !py-2.5 mb-3">
              <h4 className="mb-3">
                <MdOutlineInfo className="mr-2 h-5 w-5 -mt-1 inline-block" />
                Assessment History
              </h4>
              <div className="xl:flex xl:flex-wrap ">
                <div className="xl:w-1/2 !flex-none">
                  <p className="font-bold mb-0.5 text-sm">
                    Request Creation Date
                  </p>
                  <p className="">
                    {" "}
                    {creationDate
                      ? format(parseISO(creationDate), "MMMM do yyyy")
                      : ""}
                  </p>
                </div>
                <div className="xl:w-1/2 !flex-none">
                  <p className="font-bold mb-0.5 text-sm">Access Code</p>
                  <p className=" ">{homeInspectionAccessCode}</p>
                </div>
                {/* <div className="xl:w-1/2 !flex-none">
                  <p className="font-bold mb-0.5 text-sm">Home Assessment Type</p>
                  <p className="">{auditTitle}</p>
                </div> */}
                <div className="xl:w-1/2 !flex-none">
                  <p className="font-bold mb-0.5 text-sm">Assigned Assessor</p>
                  <p className="">
                    {assignedInspectorFirstName && assignedInspectorLastName
                      ? assignedInspectorFirstName +
                      " " +
                      assignedInspectorLastName
                      : "Unassigned"}
                  </p>
                </div>
                <div className="xl:w-1/2 !flex-none">
                  <p className="font-bold mb-0.5 text-sm">
                    Assessment Start Date
                  </p>
                  <p className=" ">
                    {assessmentStartDate
                      ? new Date(assessmentStartDate).toLocaleString()
                      : "N/A"}
                  </p>
                </div>
                <div className="xl:w-1/2 !flex-none">
                  <p className="font-bold mb-0.5 text-sm">Delivery Date</p>
                  <p className=" ">
                    {deliveryDate
                      ? new Date(deliveryDate).toLocaleString()
                      : "N/A"}
                  </p>
                </div>
                <div className="xl:w-1/2 !flex-none">
                  <p className="font-bold mb-0.5 text-sm">Approved Date</p>
                  <p className=" ">
                    {approvedDate
                      ? new Date(approvedDate).toLocaleString()
                      : "N/A"}
                  </p>
                </div>
                <div className="xl:w-1/2 !flex-none">
                  <p className="font-bold mb-0.5 text-sm">Certification Date</p>
                  <p className=" ">
                    {certificationDate
                      ? new Date(certificationDate).toLocaleString()
                      : "N/A"}
                  </p>
                </div>

                <div className="xl:w-1/2 !flex-none">
                  <p className="font-bold mb-0.5 text-sm">Approved By</p>
                  <p className=" ">
                    {approvedByName?.trim() ?? "" === "" ? "N/A" : approvedByName}
                  </p>
                </div>

                <div className="xl:w-1/2 !flex-none">
                  <p className="font-bold mb-0.5 text-sm">Certified By</p>
                  <p>{certifiedByName?.trim() ?? "" === "" ? "N/A" : certifiedByName}</p>
                </div>
              </div>
            </div>
            <div className="form-container !py-2.5">
              <h4 className="mb-3">
                <LiaComments className="mr-2 h-5 w-5 -mt-1 inline-block" />
                Notes
              </h4>

              <p className="font-bold mb-0.5 text-sm">Assigned Assessor Note</p>
              <p className="">
                {assignedInspectorNote ? assignedInspectorNote : "None"}
              </p>

              <p className="font-bold mb-0.5 text-sm">Cancellation Note</p>
              <p className="">{cancellationNote ? cancellationNote : "None"}</p>
            </div>
          </div>
          {/* <div className="col">
            <div className="form-container">
              <h4>Assessments</h4>
              <div>
                <div>
                <img
                  className="audit-splashscreen-preview"
                  src={auditPresignedURL}
                  onClick={handleNavigateToAudit}
                />
                  <h5>{auditTitle} Assessment</h5>
                 
                </div>
                
              </div>
            </div>
          </div> */}
        </div>
      </div>
    );
  };

  return mainContent();
}

export default HomeInspection;
