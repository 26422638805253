import React, { useState, useEffect, useMemo } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  flexRender,
} from "@tanstack/react-table";
import api from "../../shared/utils/api";
import { toast } from "react-toastify";
import { ERROR_MESSAGE_DURATION } from "../../shared/Constants";
import { useNavigate } from "react-router-dom";
import { LuPlusCircle } from "react-icons/lu";

function TerritoryTable() {
  const navigate = useNavigate();
  const [territories, setTerritories] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  // Fetch territories
  useEffect(() => {
    api.get("/territories").then(
      (response) => {
        setTerritories(response);
      },
      (error) => {
        toast.error("Failed to load territories: " + error.message, {
          autoClose: ERROR_MESSAGE_DURATION,
        });
      }
    );
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
        filterFn: (row, columnId, filterValue) =>
          row.getValue(columnId).toLowerCase().includes(filterValue.toLowerCase()),
      },
    ],
    []
  );

  // Table instance
  const table = useReactTable({
    data: territories,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      globalFilter: "",
      pagination: { pageSize: 10 }, // Default page size
    },
  });

  // Set global filter for the table when search term changes
  useEffect(() => {
    table.setGlobalFilter(searchTerm);
  }, [searchTerm, table]);

  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-4">
        <h2>Agencies</h2>

        <button className="btn-standard btn" onClick={() => navigate("add")}>
          <LuPlusCircle className=" -mt-0.5 h-4 w-4 inline-block" />
          &nbsp;New Agency
        </button>
      </div>

      {/* Search Bar */}
      <input
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="Search by name..."
        className="w-full p-2 border rounded mb-4"
      />

      {/* Table */}
      <table className="min-w-full table">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="px-4 py-2 text-left font-bold text-gray-700 relative"
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} onClick={() => navigate(row.original.id.toString())} className="hover:bg-gray-100 cursor-pointer">
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="px-4 py-2 whitespace-nowrap">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination Controls */}
      <div className="flex justify-between items-center mt-4">
        <div className="flex items-center space-x-2">
          <button
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
            className="px-3 py-1 border rounded-l disabled:opacity-50"
          >
            {"<<"}
          </button>
          <button
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
            className="px-3 py-1 border-t border-b disabled:opacity-50"
          >
            {"<"}
          </button>
          <button
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
            className="px-3 py-1 border-t border-b disabled:opacity-50"
          >
            {">"}
          </button>
          <button
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
            className="px-3 py-1 border rounded-r disabled:opacity-50"
          >
            {">>"}
          </button>
        </div>
        <span>
          Page{" "}
          <strong>
            {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
          </strong>
        </span>
        <select
          value={table.getState().pagination.pageSize}
          onChange={(e) => table.setPageSize(Number(e.target.value))}
          className="ml-4 border p-1 rounded"
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default TerritoryTable;