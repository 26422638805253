import * as Dialog from '@radix-ui/react-dialog';
import React, { useState, useRef, useEffect } from 'react';
import Annotation from 'react-image-annotation';
import UploadImages from './UploadImages';
import { CircleDashed, PlusCircle, Save, SquareDashed, Undo2, X, Paintbrush } from 'lucide-react';

const Annotator = ({ imageFiles, setImageFiles, imagesPreview, setImagesPreview, setAnnotatedImages, handleFileSubmit }) => {

  const [open, setOpen] = useState(false); 

  const [annotations, setAnnotations] = useState([]);
  const [annotation, setAnnotation] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const undoStackRef = useRef([]);
  const canvasRef = useRef(null);
  const [selectedShape, setSelectedShape] = useState('box');
  const [savedImage, setSavedImage] = useState(null);
  const [selectedColor, setSelectedColor] = useState('#22c55e');
  const [isDrawingMode, setIsDrawingMode] = useState(false);
  const [isDrawing, setIsDrawing] = useState(false);
  const drawingCanvasRef = useRef(null);
  const contextRef = useRef(null);
  const [drawingVersion, setDrawingVersion] = useState(0);
  const [drawingHistory, setDrawingHistory] = useState([]);
  
  const Box = ({ children, geometry, style }) => (
    <div
      style={{
        ...style,
        position: 'absolute',
        left: `${geometry.x}%`,
        top: `${geometry.y}%`,
        height: `${geometry.height}%`,
        width: `${geometry.width}%`,
      }}
    >
      {children}
    </div>
  )

  function renderSelector({ annotation }) {
    const { geometry } = annotation;
    if (!geometry) return null
    return (
      <Box
        geometry={geometry}
        style={{
          border: `dotted 3px ${selectedColor}`,
          borderRadius: selectedShape === 'circle' ? '50%' : '10px',
        }}
      />
    )
  }

  function renderHighlight({ annotation }) {
    const { geometry } = annotation;
    if (!geometry) return null
    return (
      <Box
        key={annotation.data.id}
        geometry={geometry}
        style={{
          border: `solid 3px ${annotation.data.color}`,
          borderRadius: annotation.data.shape === 'circle' ? '50%' : '10px',
        }}
      />
    )
  }

  function renderContent({ annotation }) {
    const { geometry } = annotation;
    const idIntegerPart = Math.floor(geometry?.height);
    return (
      <div
        key={annotation.data.id}
        style={{
          background: `${idIntegerPart % 2 !== 0 ? '#efc901' : '#efc901'}`,
          color: 'black',
          paddingTop: 4,
          paddingRight: 10,
          paddingLeft: 10,
          fontSize: 15,
          borderRadius: '6px',
          position: 'absolute',
          left: `${geometry.x}%`,
          top: `${geometry.y - 9}%`
        }}
        className='hidden'
      >
        {annotation.data && annotation.data.text}
      </div>
    )
  }

  function renderEditor (props) {
    const { geometry } = props.annotation
    if (!geometry) return null
    return (
      <div
        style={{
          background: 'white',
          borderRadius: 3,
          position: 'absolute',
          left: `${geometry.x}%`,
          top: `${geometry.y + geometry.height}%`,
        }}
        className="z-40 p-2 mt-[5px] border relative rounded"
      >
        {/* <button 
          onClick={() => props.onChange({})} 
          className="absolute -right-2 -top-2 w-6 h-6 rounded-full flex items-center justify-center bg-gray-200 hover:bg-gray-300 text-black"
        >
          <X size={14} />
        </button> */}
        <input
          onChange={e => props.onChange({
            ...props.annotation,
            data: {
              ...props.annotation.data,
              text: e.target.value
            }
          })}
          placeholder="Add Comment"
          className="hidden p-2 focus:outline-none"
        />
        <div className='flex flex-col gap-2'>
          <button onClick={props.onSubmit} className={`px-3 pt-1 rounded bg-[#efc901] hover:bg-[#f3d646]`}>
            Add
          </button>
          <button onClick={() => props.onChange({})} className={`px-3 pt-1 rounded text-white bg-[#b53e42] hover:bg-[#a2383b]`}>
            Cancel
          </button>
        </div>
      </div>
    )
  }

  const onChange = (newAnnotation) => {
    setAnnotation(newAnnotation);
  };

  const onSubmit = (newAnnotation) => {
    const { geometry, data } = newAnnotation;
    setAnnotation({});
    const newAnnotationData = {
      geometry,
      data: {
        ...data,
        id: Math.random(),
        imageId: selectedImage,
        shape: selectedShape,
        color: selectedColor,
      },
    };
    setAnnotations([...annotations, newAnnotationData]);
    undoStackRef.current.push(annotations);
  };

  const handleUndo = () => {
    if (isDrawingMode) {
      if (drawingHistory.length > 1) {
        const newHistory = drawingHistory.slice(0, -1);
        const previousDrawing = newHistory[newHistory.length - 1];
        const context = drawingCanvasRef.current.getContext('2d');
        context.clearRect(0, 0, drawingCanvasRef.current.width, drawingCanvasRef.current.height);
        
        if (previousDrawing) {
          const img = new Image();
          img.onload = () => {
            context.drawImage(img, 0, 0);
            setDrawingVersion(prev => prev + 1);
          };
          img.src = previousDrawing;
        }
        
        setDrawingHistory(newHistory);
      } else if (undoStackRef.current.length > 0) {
        const lastAnnotations = undoStackRef.current.pop();
        setAnnotations(lastAnnotations);
      }
    } else {
      if (undoStackRef.current.length > 0) {
        const lastAnnotations = undoStackRef.current.pop();
        setAnnotations(lastAnnotations);
      }
    }
  };

  const drawAnnotationsOnCanvas = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    const img = new Image();
    img.src = selectedImage;
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      
      annotations
        .filter((anno) => anno.data.imageId === selectedImage)
        .forEach((anno) => {
          const { geometry } = anno;
          const x = (geometry.x / 100) * canvas.width;
          const y = (geometry.y / 100) * canvas.height;
          const width = (geometry.width / 100) * canvas.width;
          const height = (geometry.height / 100) * canvas.height;
          ctx.strokeStyle = anno.data.color;
          ctx.lineWidth = 8;
          ctx.beginPath();
          if (anno.data.shape === 'circle') {
            const centerX = x + width / 2;
            const centerY = y + height / 2;
            const radiusX = width / 2;
            const radiusY = height / 2;
            ctx.ellipse(centerX, centerY, radiusX, radiusY, 0, 0, 2 * Math.PI);
          } else {
            ctx.roundRect(x, y, width, height, 10);
          }
          ctx.stroke();
          if (anno.data.text) {
            ctx.fillStyle = anno.data.color;
            ctx.font = 'bold 30px Arial';
            ctx.fillText(anno.data.text, x, y - 20);
          }
        });

      if (drawingCanvasRef.current) {
        ctx.drawImage(drawingCanvasRef.current, 0, -30);
      }

      canvas.toBlob((blob) => {
        const file = new File([blob], 'annotated_image.png', { type: 'image/png' });
        setSavedImage(file);
      }, 'image/png');
    };
  };

  useEffect(() => {
    if (open) {
      drawAnnotationsOnCanvas();
    }
  }, [annotations, selectedImage, drawingVersion]);
  
  useEffect(() => {
    if (imageFiles.length > 0 && !selectedImage) {
      setSelectedImage(imagesPreview[0]);
    }
  }, [imageFiles, imagesPreview, selectedImage]);

  useEffect(() => {
    if (selectedImage && drawingCanvasRef.current) {
      const canvas = drawingCanvasRef.current;
      const context = canvas.getContext('2d');
      const img = new Image();
      img.src = selectedImage;
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        
        canvas.style.width = '100%';
        canvas.style.height = 'auto';
        canvas.style.maxHeight = '400px';
        
        if (drawingHistory.length > 0) {
          const lastDrawing = new Image();
          lastDrawing.onload = () => {
            context.drawImage(lastDrawing, 0, 0);
          };
          lastDrawing.src = drawingHistory[drawingHistory.length - 1];
        }
        
        context.strokeStyle = selectedColor;
        context.lineWidth = 5;
        context.lineCap = 'round';
        context.lineJoin = 'round';
        contextRef.current = context;
      };
    }
  }, [selectedImage, isDrawingMode, selectedColor, drawingHistory]);

  const startDrawing = ({ nativeEvent }) => {
    if (!isDrawingMode) return;
    
    const canvas = drawingCanvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;
    
    const x = (nativeEvent.clientX - rect.left) * scaleX;
    const y = (nativeEvent.clientY - rect.top) * scaleY;
    
    contextRef.current.beginPath();
    contextRef.current.moveTo(x, y);
    setIsDrawing(true);
  };

  const draw = ({ nativeEvent }) => {
    if (!isDrawing || !isDrawingMode) return;
    
    const canvas = drawingCanvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;
    
    const x = (nativeEvent.clientX - rect.left) * scaleX;
    const y = (nativeEvent.clientY - rect.top) * scaleY;
    
    contextRef.current.lineTo(x, y);
    contextRef.current.stroke();
  };

  const stopDrawing = () => {
    if (!isDrawingMode) return;
    contextRef.current.closePath();
    setIsDrawing(false);
    
    if (isDrawing) {
      const drawingState = drawingCanvasRef.current.toDataURL();
      setDrawingHistory(prev => [...prev, drawingState]);
      setDrawingVersion(prev => prev + 1);
    }
  };

  function handleSave() {
    setAnnotatedImages(prev => [...prev, savedImage]);
    resetState();
    handleFileSubmit(savedImage);
  }

  const resetState = () => {
    setSelectedImage(null);
    setImagesPreview([]);
    setImageFiles([]);
    setOpen(false);
    setAnnotations([]);
    setAnnotation({});
    undoStackRef.current = [];
    setSelectedShape('box');
    setSavedImage(null);
    setSelectedColor('#22c55e');
    setIsDrawingMode(false);
    setIsDrawing(false);
    setDrawingVersion(0);
    setDrawingHistory([]);
  };

  useEffect(() => {
    if (!open) {
      resetState();
    }
  }, [open]);

  useEffect(() => {
    if (isDrawingMode && drawingCanvasRef.current) {
      setDrawingHistory([drawingCanvasRef.current.toDataURL()]);
    }
  }, [isDrawingMode]);

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger>
        <button className='flex flex-col items-center justify-center gap-1 w-20 h-20 border rounded'>
          <PlusCircle size={16} className='mt-1' />  <span className='mt-[1px] text-sm'>Add</span>
        </button>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="z-[100] fixed inset-0 bg-[#081A3F]/20 data-[state=open]:animate-overlayShow" />
        <Dialog.Content className="z-[120] pb-3 data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] max-h-[40rem] w-[90vw] max-w-[560px] translate-x-[-50%] translate-y-[-50%] rounded-xl bg-[#ffffff] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none">
          <div className='border-b border-b-gray-100'>
            <Dialog.Title className="mt-3 ml-5 text-xl font-semibold antialiased">
              <span>
                Add Image
              </span>
            </Dialog.Title>
          </div>
          <div className='p-6'>
          <div>
            <div className="w-full md:w-[500px] m-auto cursor-crosshair">
              {selectedImage && (
                <>
                  <div className="flex justify-between items-center mb-[16px] w-full">
                    <div className='flex items-center gap-2.5'>
                      <button 
                        className={`flex flex-col items-center justify-center gap-1 w-14 h-14 border-[2.5px] rounded ${selectedShape === 'box' ? 'text-black border-black' : 'text-black'}`}
                        onClick={() => {
                          setSelectedShape('box');
                          setIsDrawingMode(false);
                        }}
                      >
                        <SquareDashed size={16} className='mt-1' />  <span className='mt-[1px] text-sm'>Box</span>
                      </button>
                      <button 
                        className={`flex flex-col items-center justify-center gap-1 w-14 h-14 border-[2.5px] rounded ${selectedShape === 'circle' ? 'text-black border-black' : 'text-black'}`}
                        onClick={() => {
                          setSelectedShape('circle');
                          setIsDrawingMode(false);
                        }}
                      >
                        <CircleDashed size={16} className='mt-1' />  <span className='mt-[1px] text-sm'>Circle</span>
                      </button>
                      <button 
                        className={`flex flex-col items-center justify-center gap-1 w-14 h-14 border-[2.5px] rounded ${isDrawingMode ? 'text-black border-black' : 'text-black'}`}
                        onClick={() => {
                          setIsDrawingMode(!isDrawingMode);
                          setSelectedShape(null);
                        }}
                      >
                        <Paintbrush size={16} className='mt-1' />
                        <span className='mt-[1px] text-sm'>Draw</span>
                      </button>
                      <button 
                        className={`flex flex-col items-center justify-center gap-1 w-14 h-14 border-[2.5px] border-[#22c55e] rounded ${selectedColor === '#22c55e' ? 'border-black' : ''}`}
                        onClick={() => setSelectedColor('#22c55e')}
                        style={{ backgroundColor: '#22c55e' }}
                      >
                        <span className='mt-[1px] text-sm text-white'>Green</span>
                      </button>
                      <button 
                        className={`flex flex-col items-center justify-center gap-1 w-14 h-14 border-[2.5px] border-[#ef4444] rounded ${selectedColor === '#ef4444' ? 'border-black' : ''}`}
                        onClick={() => setSelectedColor('#ef4444')}
                        style={{ backgroundColor: '#ef4444' }}
                      >
                        <span className='mt-[1px] text-sm text-white'>Red</span>
                      </button>
                    </div>
                    <div className='flex items-center gap-2.5'>
                      <button className="flex flex-col items-center justify-center gap-1 w-14 h-14 border rounded" onClick={handleUndo} type="button">
                        <Undo2 size={16} className='mt-1' />  <span className='mt-[1px] text-sm'>Undo</span>
                      </button>
                      <button 
                        className="flex flex-col items-center justify-center gap-1 w-14 h-14 border-[#efc803] rounded bg-[#efc803]" onClick={() => handleSave(savedImage)} type="button"
                      >
                        <Save size={16} className='mt-1' />  <span className='mt-[1px] text-sm'>Save</span>
                      </button>
                    </div>
                  </div>    
                  <div className="flex gap-3 justify-center items-center mb-[15px] w-full">
                  </div>
                  <Annotation
                    src={selectedImage}
                    alt="Annotate image"
                    annotations={annotations.filter((anno) => anno.data.imageId === selectedImage)}
                    value={annotation}
                    type={annotation.type}
                    className="h-auto max-h-[400px] object-cover rounded-lg"
                    onChange={onChange}
                    onSubmit={onSubmit}
                    allowTouch
                    renderOverlay={() => null}
                    renderSelector={renderSelector}
                    renderHighlight={renderHighlight}
                    renderContent={renderContent}
                    renderEditor={renderEditor}
                  />
                </>
              )}
            </div>
            {imageFiles.length === 0 && (
              <div className='md:w-[400px] mx-auto'>
                <UploadImages
                  imageFiles={imageFiles}
                  setImageFiles={setImageFiles}
                  imagesPreview={imagesPreview}
                  setImagesPreview={setImagesPreview}
                />
              </div>
            )}
            <canvas
              ref={canvasRef}
              style={{ display: 'none' }}
            />
            {selectedImage && isDrawingMode && (
              <canvas
                ref={drawingCanvasRef}
                onMouseDown={startDrawing}
                onMouseMove={draw}
                onMouseUp={stopDrawing}
                onMouseLeave={stopDrawing}
                className="absolute top-[120px] left-0 w-full h-auto max-h-[400px] z-10"
                style={{ objectFit: 'contain' }}
              />
            )}
          </div>
          </div>
          <Dialog.Close asChild>
            <button
              className="absolute top-3.5 right-3 h-7 w-[2.2rem] flex items-center justify-center outline-none"
              aria-label="Close"
            >
              <X 
                size={24} 
                className='hover:rotate-90 transition duration-300'
              />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
    
  );
};

export default Annotator;